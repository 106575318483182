.hand_twoc {
    /* margin-top: -18px; */
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.hand_threec {
    /* margin-top: -15px; */
    line-height: 15px;
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}
.qr_fifteenc {
    padding: 10px;
    /* position: fixed; */
    width: 100%;
    height: 75px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    /* box-shadow: 0px -4px 4px rgb(0 0 0 / 5%); */
}
textarea::placeholder {
    color: #0000ff; /*hexcode to change color of placeholder */
}

.qr_sixteenc {
    margin-top: 15px;
    margin-left: auto;
    /* margin-right: auto; */
    padding: 10px 20px;
    width: 343px;
    height: 44px;
    background: #F4B644;
    border-radius: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 2500px){
    .react-responsive-modal-modal {
        width: 27% !important;
        margin: auto;
        padding: 10px !important;
        border-radius: 10px !important;
    }
    .hand_foot_two {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        color: #161E40;
        margin-top: 4px !important;
        margin-bottom: 10px !important;
    }
}
.ser_his{
    width: 100%;
    height: auto;
    border-radius: 8px;
    background-color: #EAECEE;
    margin-top: 20px;
    padding: 10px;
}
.web_cart_col_monec {
    padding: 10px;
    width: 100%;
    height: 88px;
    background-color: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
    border: 1px solid #F4B644;
}
.web_cart_col_mfivec {
    padding: 10px;
    width: 100%;
    height: 88px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
}
.ser_his_p{
    font-size: 10px;
    color: green;
}
.ser_his_p_two{
    font-size: 10px;
}
.ser_his_p_three{
    font-size: 12px;
    font-weight: 600;
    margin-top: 1px;
}
.ser_his_p_four{
    font-size: 10px;
    color: #F4B644 ;
}
.ser_his_p_five{
    transform: rotate(180deg);
}