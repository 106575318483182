.coool{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: static;
    left: 300px;
    top: 0px;
    background: #161E40;
    border-radius: 5px;
}
.re_img{
    left: 12.56%;
    right: 12.56%;
    top: 12.5%;
    bottom: 12.5%;
}
.re_text{
    height: 17px;
    left: 25px;
    top: 1.5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #161E40;
}
.collv{
    width: fit-content;
    padding: 5px;
    background: #E8E9EB;
    border-radius: 3px;
    margin-right: 15px;
}
.recent_para{
    left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #161E40;
    white-space: nowrap;
}
.roow{
    margin-top: 10px !important;
}
.img_po{
    max-width:unset !important;
}
.recent_paras{
    left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
    white-space: nowrap;
    padding-top: 4px;
}
.collvs{
    text-align: center;
    height: 28px;
    padding: 5px;
    background: #161E40
    ;
    border-radius: 6px;
    margin-right: 15px;
    
}