.form-controlss {
    border: 1px solid #ff0000 !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.25rem;
}
body::-webkit-scrollbar-thumb {
    background-color: blue;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid orange;  /* creates padding around scroll thumb */
  }
.icon-loading{
    margin-top: -12px;
    margin-left: 30px;
    width: 50px;
}
.comparacart {
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 4px;
}
.mob_pay_checkssss {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    color: #ff0000;
}
.wrong_cart{
    margin: auto;
}
.ReactModal__Content{
    width: 90%;
    height: 30%;
    border-radius: 12px !important;
    padding: 30px !important;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}
.mod_div{
    margin-top: 5px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
}
.cart_hell_one{
    margin-top: 10px;
    width: 80%;
    height: 37px;
    border: 2px solid #161E40;
    border-radius: 5px;
}
.cart_hell_two{
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #161E40;
}
.navclass{
    flex-wrap: unset !important;
    position: sticky;
}
.nav-tabs .nav-link {
    color: #9A9A9A ;
}
.nav-tabs .nav-link.active {
    color: black !important;
    font-weight: 600;
}
.jupoFI{
    width: unset !important;
}
.backrow{
    align-items: center;
    width: 170px;
    height: 37px;
    left: 0px;
    top: 0px;
    background-color: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
    margin: auto;
    position: sticky;
}
.immm{
    width: 25px;
}
.txxxtt{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #F4B644
}
.backrows {
    align-items: center;
    width: 170px;
    height: 37px;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    margin: auto;
    position: sticky;
    background-color: #F4B644;
}
.backrows .txxxtt {
    color: #ffffff
}
.firow{
    margin-top: 30px;
}
.gwZiig{
    overflow-y: hidden;
}
.txtt{
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #9A9A9A;
    padding-bottom: 4px;
}
.txttss{
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #161E40;
    border-bottom-color: #F4B644;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 4px;
}
.r1oo{
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 0px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 10px;
    margin-top: 25px;
}
.product-grid .title {
    padding-bottom: 8px;
}
.imcol1{
    padding-right: 10px;
}
.imm{
    width: 111px;
}
@media (min-width: 727px) and (max-width: 2300px){
    .hiiiiiii input {
        width: 46px !important;
        padding-right: unset !important;
    }
}
@media (min-width: 450px) and (max-width: 726px){
    .imm{
        width: 100% !important;
    }
}

.paratwodfgdg{
    margin-bottom: 0px ;
    font-size: 9px !important;
    line-height: 1.2 !important;
    color: #161E40;
    font-weight: 600;
}
@media (min-width: 0px) and (max-width: 500px){
    .paratwogbdgbd {
        margin-bottom: unset !important;
        font-size: 8px !important;
        line-height: 1.2 !important;
    }
    .paratwo {
        margin-bottom: -21px !important;
        font-size: 8px !important;
        line-height: 1.2 !important;
    }
    .paraone {
        margin-bottom: -3px !important;
    }
}

.mob_login_container_threes {
    width: 100%;
    height: 159px;
    left: 16px;
    top: 225.3px;
    background: rgba(244, 182, 68, 0.25);
    border: 1px solid #F4B644;
    box-sizing: border-box;
    border-radius: 5px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIzSURBVHgBrVZtUhpBEH09a1KYimZvEHKC4A3wBIE/cckf5AQxJxBOEHMC8RdiUhVuEG4Qc4JsbjApS4uyimm7B1lF9gvw/WF2Z6bfTPfrxxJK4PUgqhuiGgy/J+ZQ3zEQs6M/ATC2rfM4bz/lTe5+j47J0ZGEDJEfZeymOLtqnffTp1MQXnyqMbufMqxiBTChb6boPb3ZEsnOj1ZbFp4Unj4bMTnsPyZaILm/wW9sjgUikxAMoup9ip4DVQ5wOn9ISFyAY6xYg1ww6juD6DAh0VsQ4xBrg2zaW2P8wWckjqiBDUCYdqQGnRSyaqg95hcZ/oA1IcF79uBi5LbwNk2Rjrgxq4nkD2uBRqKgrjqCkHUzFlXJq8rgb0YQm9MvMd1M9lCphLL/F7JFExtkn9KS4z2Cay7nWuewr6MCAg+DysSmT3EoeW5rvpVMT5RQSKG10dyr7RKyJ2tsc2SzJKh53h22vmrA2cn5cl5ob57gIxRAGvyft5U3wwPp9DwZa3Bqzm1CCx3M0lQIZvriScLhxwbDFFlKvEod5pA977b86OXtGLfbtsB5RYUk5sm2LAEz+v/l9l5dWhdi1yuxLcQK/mYYPmYiYRsNT/QfDs8EL5CnVu8nXkykJx6kujYB8zd1gvnzAolPmxZ3gxt5gmi4IO3MDwmxm66oqI3yNVD1aZOOl4jzdqmvyU+dDX+WpbXlFdLEzJeOcRZMJiPbGaU3NUoiPG2EYoYPRIGxuL6JswI/xh20A/xUdBGDeQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: right top;
    padding: 10px;
}
.mob_login_container_threess{
    width: 100%;
    height: 159px;
    left: 16px;
    top: 225.3px;
    background: #EFEFEF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
}

.inputttt_red{
    border-color: red !important;
}
.resend_otp_web{
    cursor: pointer;
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #F4B644;
}
.paraone{
    margin-bottom: -3px;
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
}
.paratwo{
    margin-bottom: -8px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #161E40;
}
.parathree{
    height: 3px;
    width: 157.99px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}
.btoone{
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100%;
    height: 34px;
    left: 0px;
    top: 62px;
    background: #161E40;
    border-radius: 3px;
    margin-top: 25px;
    margin-bottom: 5px;
}
.proo_crt_onee{
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #F4B644;
    margin-top: 31px;
}
.parafour{
    text-align: center;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}
.web_collection{
    margin-left: auto;
    margin-right: auto;
    padding: 4px;
    width: 180px;
    height: 40px;
    border-width: 3px;
    border-color: #161E40;
    background-color: white;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    border-radius: 6px;
    background-color: #161E40;
}
/* .web_collection img{
    margin-top: -5px !important;
} */
.web_text_col{
    padding-top: 3px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
}
.web_cat_two{
    font-weight: 600;
    font-size: 19.749px;
    text-align: center;
    color: #424D59;
}
.web_cat_one{
    margin-top: 50px;
}
.web_cat_three{
    border-right-width: 2px;
    border-style: solid;
}
.web_cat_four{
    margin-top: 5px !important;
    width: 24px;
    height: 3px !important;
    color: #F4B644;
}
.web_cat_five{
    max-width: 1800px !important;
}
.de_veri_css{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #ffffff !important;
    white-space: nowrap;
    margin-top: 3px;
}
.info_img{
    margin-top: 6px;
    margin-right: 4px;
    width: 16px;
    height: 16px;
}
.ino_veri{
    font-weight: 500;
    font-size: 12px;
}
.info_veri_two{
    font-size: 15px;
    color: #333;
}
.compara23333{
    font-weight: 600;
    font-size: 24px !important;
    text-align: center;
    color: #000 !important;
    margin: auto;
}
.cool2_veri{
    width: 100% !important;
}
.product-details-content .product-details-price {
    margin: 1px 0 -1px !important;
}
.product-details-content .pro-details-list {
    margin: unset !important;
    padding: 0 0 13px !important;
    border-bottom: unset !important;
}
.verti_img{
    margin-top: 4px;
}
.pro_conts{
    width: 144px;
    height: 44px;
    justify-content: center;
    align-items: center;
    padding: 10px 11px;
    top: 0px;
    background: #161E40;
    border-radius: 5px;
}
.pro_cont1s{
    width: 144px;
    height: 44px;
    justify-content: center;
    align-items: center;
    padding: 7px 11px;
    top: 0px;
    border-width: 2px;
    border-style: solid;
    border-color: #161E40;
    border-radius: 5px;
}
.product-details-content .pro-details-quality {
    margin: unset !important;
}
.product-details-content .pro-details-quality .pro-details-cart a{
    border-radius: 8px !important;
    padding: 12px 13px 13px !important;
}
.btn-hover a::after{
    border-radius: 8px !important;
}
.propara17444{
    padding-left: 15px;

}
.propara13444{
    text-align: left;
    font-weight: 600;
    padding-top: 1px;
    font-size: 14px;
    color: "#000" !important;
} 
@media (min-width: 1200px)
{
    .lolololo {
        max-width: 1396px !important;
    }
    .lolconttt{
        max-width: 1520px !important;
    }
}

.slick-arrow {
    background: unset !important;
    border: 0;
}
@media (min-width:1440px) and (max-width: 1650px) {
    .web_cat_five{
        max-width: 1326px !important;
    }
}
@media (min-width:1300px) and (max-width: 1440px) {
    .web_cat_five{
        max-width: 1132px !important;
    }
}
@media (min-width:1100px) and (max-width: 1300px) {
    .web_cat_five{
        max-width: 950px !important;
    }
}
@media (min-width:900px) and (max-width: 1100px) {
    .web_cat_five{
        max-width: 756px !important;
    }
}
@media (min-width:726px) and (max-width: 900px) {
    .web_cat_five{
        max-width: 620px !important;
    }
}

@media (min-width:726px) and (max-width: 900px) {
    .web_cat_five{
        max-width: 620px !important;
    }
}
@media (min-width:1650px) and (max-width: 1950px) {
    .web_cat_five{
        max-width: 1500px !important;
    }
}

@media (min-width: 900px) and (max-width: 1100px){
    .thr_one {
      width: 80px !important;
      height: 30px !important;
    }
    .web_collection {
      width: 80px !important;
      height: 30px !important;
    }
    .thr_three {
      font-size: 8px;
    }
    .sdfjsdogvodigd{
      font-size: 8px;
    }
    .web_collection{
        padding: unset !important;
    }
    .thr_one {
        padding: unset !important;
    }
  }

  @media (min-width: 726px) and (max-width: 900px){
    .thr_one {
      width: 60px !important;
      height: 30px !important;
    }
    .web_collection {
      width: 60px !important;
      height: 30px !important;
    }
    .thr_three {
      font-size: 6px;
    }
    .sdfjsdogvodigd{
      font-size: 6px;
    }
    .web_collection{
        padding: unset !important;
    }
    .thr_one {
        padding: unset !important;
    }
  }