.servicedd_p{
    padding: 20px;
    width: 100%;
    height: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
}
.servicedd_p_one{
    font-size: 12px;
    font-weight: 500;
}
.servicedd_p_twooo{
    cursor: pointer;
    width: 100%;
    height: 44px;
    background: #008000;
    border-radius: 5px;
    padding: 5px;
}
.servicedd_p_fivee{
    margin-top: 6px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}
.hand_twod {
    margin-top: -7px;
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.hand_threed {
    margin-top: -2px;
    line-height: 15px;
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}