.stepper-wrapper {
    /* styles of default stepper */
  }
  .stepper-wrapper * {
    box-sizing: border-box;
  }
  .stepper-wrapper .stepper-head {
    display: flex;
    position: relative;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* styles of inline stepper */
    /* styles of vertical stepper */
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-step:after {
    top: 50%;
    transform: translateY(-50%);
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-indicator,
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    display: inline-block;
    vertical-align: middle;
  }
  .stepper-wrapper .stepper-head.inline-stepper-head .stepper-label {
    text-align: left;
    padding: 0 0.5rem;
    background: #fff;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head {
    flex-direction: column;
    width: auto;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step {
    display: flex;
    align-items: center;
    text-align: start;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 1.5rem;
    right: auto;
    top: 2rem;
    bottom: -0.5rem;
    width: 0;
    height: auto;
    border-left: 0.125rem solid #e3e8ec;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-step.rightToLeft:after {
    right: 1.5rem;
    left: auto;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head .stepper-label {
    -webkit-padding-start: 1rem;
            padding-inline-start: 1rem;
  }
  .stepper-wrapper .stepper-head.vertical-stepper-head + .stepper-body {
    -webkit-margin-start: 3.5rem;
            margin-inline-start: 3.5rem;
  }
  .stepper-wrapper .stepper-head .stepper-step {
    position: relative;
    text-align: center;
    padding: 0.5rem;
    flex-basis: 100%;
  }
  .stepper-wrapper .stepper-head .stepper-step.rightToLeft:after {
    left: -50%;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-indicator-info {
    border-color: #4caf50;
    background-color: #4caf50;
    cursor: pointer;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-complete .stepper-label {
    color: #4caf50;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-active .stepper-indicator-info {
    border-color: #627c90;
    background-color: #627c90;
    cursor: initial;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-active .stepper-label {
    color: #627c90;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-indicator-info {
    border-color: #f1c40f;
    background-color: #f1c40f;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-warning .stepper-label {
    color: #f1c40f;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-error .stepper-indicator-info {
    border-color: #e95a4b;
    background-color: #e95a4b;
  }
  .stepper-wrapper .stepper-head .stepper-step.is-error .stepper-label {
    color: #e95a4b;
  }
  .stepper-wrapper .stepper-head .stepper-step:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: 1.5rem;
    width: 100%;
    height: 0.125rem;
    background-color: #e3e8ec;
    z-index: 1;
  }
  .stepper-wrapper .stepper-head .stepper-step:last-child:after {
    display: none;
  }
  .stepper-wrapper .stepper-head .stepper-indicator {
    position: relative;
    display: block;
    z-index: 2;
  }
  .stepper-wrapper .stepper-head .stepper-indicator-info {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #e3e8ec;
    border-radius: 50%;
    background-color: #e3e8ec;
    font-size: 1rem;
    line-height: 2.5rem;
    text-align: center;
    color: #fff;
    z-index: 2;
  }
  .stepper-wrapper .stepper-head .stepper-indicator-info .stepper-tick {
    width: 14px;
    fill: #ffffff;
  }
  .stepper-wrapper .stepper-head .stepper-label {
    position: relative;
    display: block;
    margin: 0.5rem 0;
    color: #cfd7de;
    z-index: 2;
  }
  .stepper-wrapper .stepper-body {
    min-height: 100px;
    padding: 10px 0;
  }
  .stepper-wrapper .stepper-footer {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .stepper-wrapper .stepper-footer-btn {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    cursor: pointer;
    border: none;
    outline: none;
  }
  .stepper-wrapper .stepper-footer-btn.primary {
    color: #fff;
    background-color: #1976d2;
  }
  .stepper-wrapper .stepper-footer-btn.success {
    color: #fff;
    background-color: #4caf50;
  }
  .stepper-wrapper .stepper-footer-btn:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    cursor: not-allowed;
  }
  .track_tr_one{
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
    padding: 15px;
  }
  .track_para_one{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
  }
  .track_para_two{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
    margin-top: -20px;
  }
  .track_para_three{
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-top: -10px;
  }
  .track_para_four{
    margin-top: 8px;
  }
 