.mob_couon_card{
    width: 100%;
    height: 44px;
    left: 0px;
    top: 0px;
    background: #EAECEE;
    border-radius: 5px;
    padding: 7px;
}
.kyc_in_onessss{
    height: 30px;
    background-color: #EAECEE !important;
    border: 1px solid #EAECEE !important;
}
.coupon_box_two{
    width: 75px;
    height: 31px;
    left: 263px;
    background: #F4B644;
    border-radius: 3px;
    margin: 0px 10px;
}
.coupon_p_one{
    padding-top: 4px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}
.coupon{
    width: 100%;
    height: 104px;
    left: 0px;
    top: 0px;
    background:  rgba(244, 182, 68, 0.25);
    border-radius: 8px;
    padding: 20px;
    position: relative;
}
.coupon_code{
    margin-top: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.coupon_code_line{
    border-right-width: 4px;
    border-right-style: dotted;
    border-right-color: #a1a1a1;
}
.coupon_offer{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}
.cooupon_detail{
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-top: -20px;
}
.coupon_detail{
    font-weight: 500;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.3);
    margin-top: -20px;
}
.half_circle{
    position: absolute;
    width: 20px;
    height: 30px;
    top: 77%;
    left: 4%;
    transform: translate(-50%, -50%);
    border-radius: 0 150px 150px 0;
    background-color: rgb(255, 255, 255);
    margin-top: -30px;
    margin-left: -11px;
}
.half_circle2{
    position: absolute;
    width: 20px;
    height: 30px;
    top: 50%;
    left: 98%;
    transform: translate(-50%, -50%);
    border-radius: 150px 0 0 150px;
    background-color: rgb(255, 255, 255);
}
.pay_succ{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #000000;
}
.pay_succ1{
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #000000;
}