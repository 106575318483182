.coupon_box_twos {
    width: 95%;
    height: 100%;
    left: 263px;
    background: #F4B644;
    border-radius: 3px;
    margin: 0px 10px;
}
.cop_ho_one{
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.cop_ho_two{
    width: 100%;
    height: 200px;
    background: linear-gradient(46.09deg, #E6E6E6 -11.71%, #F8F8F8 53.3%, #E3E3E3 118.3%);
    position: relative;
}
.cop_ho_two::before{
    content:""; display:block; padding:5px; background-color:#f60;
    border:2px solid white; position: absolute; top:-2px;
    right:-2px;
}
.cop_ho_three{
    background: linear-gradient(99.57deg, #F4B644 3.71%, #FFA500 107.81%);
    width: 100%;
    height: 70px;
}