@media only screen and (max-width: 1200px) {
    .product-details-content p {
        font-size: 12px !important;
    }
}
.mob_cart_cont{
    width: 100%;
    /* height: 200px; */
    left: 16px;
    top: 113px;
    background: #F4F4F4;
    border-radius: 10px;
}
.coool8{
    padding: 15px;
}
.cartpara1{
    font-weight: 600;
    font-size: 14px;
    color: #161E40;
    margin-top: -20px;
}
.cartpara2{
    font-weight: 500;
    font-size: 10px;
    color: #676767;
    margin-top: -20px;
}
.cartpara14{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
    margin-top: -5px;
}
.cartpara5{
    font-weight: 900;
    font-size: 10px;
    line-height: 12px;
    color: #161E40;
    margin-top: -15px;
}
.cartoone{
    margin-top: 20px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #F4B644;
}
.cartotwo{
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    color: #161E40;
    /* margin-top: -10px; */
}
.cart_cont{
    width: 100%;
    height: 32px;
    left: 235.75px;
    top: 108.82px;
    background: #FFFFFF;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
.boot_conone{
    width: 100%;
    height: 35px;
    left: 7.5px;
    top: 154.32px;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 15px;
}
.boot_contwo{
    width: 100%;
    height: 35px;
    left: 176.5px;
    top: 154.32px;
    background: rgba(200, 32, 45, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    line-height: 20px;
    text-align: justify;
}
.boot_conthree{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
    margin:auto;
}
.boot_confour{
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #C8202D;
    padding-top: 5px;
}
.boot_confive{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #737373;
}
.boot_six{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
}
.cart_bynn{
    width: 100%;
    height: 54px;
    right: 16px;
    bottom: 106.77px;
    background: #F4B644;
    border-radius: 5px;
    margin-top: 30px;
}
.boot_seven{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    /* margin-left: auto;
    margin-right: auto; */
    margin-top: 16px;
}
.crtbtimg{
    width: 30px;
    height: 30px;
    /* margin-right: 90px; */
    margin-top: 12px;
}
.cart_wrapper .cart_details .form_row{
    display: unset !important;
}
/* .imageee{
    box-shadow: rgb(81 81 81 / 8%) 0px 19px 38px, rgb(0 0 0 / 12%) 0px 15px 12px;
} */
.css-i9cy6v{
    /* height: 195px; */
}
@media (min-width: 768px) and (max-width: 1023px){
    .cart_wrapper {
        max-height: 1135px !important;
    }
}
@media (min-width: 768px) and (max-width: 1023px){
    .cart_wrapper .cart_details {
        flex: 0 0 40% !important;
        margin: auto;
    }
}
@media (min-width: 400px) and (max-width: 1300px){
    .pro_home_one {
        width: 95% !important;
    }
}
@media (min-width: 727px) and (max-width: 1040px){
    .txtt {
        font-size: 9px !important;
    }
    .txttsss{
        font-size: 9px !important;
    }
}

@media (min-width: 1040px) and (max-width: 1400px){
    .txtt {
        font-size: 11px !important;
    }
    .txttsss{
        font-size: 11px !important;
    }
}
@media (min-width: 1400px) and (max-width: 1700px){
    .txtt {
        font-size: 12px !important;
    }
    .txttsss{
        font-size: 12px !important;
    }
}
.web_cart_col_8{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    border-radius: 6px;
}
.web_cart_col_4{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
    border-radius: 6px;
}
.web_cart_col_one{
    padding: 10px;
}
.web_cart_col_two{
    font-size: 18px;
    font-weight: 500;
}
.web_cart_col_three{
    font-weight: 600;
    font-size: 12px;
    color: rgba(22, 30, 64, 0.5);
}
.web_cart_col_four{
    font-weight: 600;
    font-size: 15px;
    color: #161E40;
}
.web_cart_col_five{
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}
.web_cart_col_six{
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #F4B644;
}
.web_cart_col_seven{
    font-weight: 600;
    font-size: 15px;
    color: #161E40;
    text-align: center;
}
.web_cart_col_eight{
    width: 100%;
    background-color: #ebebeb;
    height: 38px;
    border-radius: 5px;
}
.web_cart_col_nine{
    width: 100%;
    background-color:  rgba(200, 32, 45, 0.25);
    height: 38px;
    border-radius: 5px;
}
.web_cart_col_ten{
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #C8202D;
    padding-top: 8px;
}
.web_cart_col_eleven{
    font-size: 13px;
    color: #666666;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.web_cart_col_twelve{
    font-size: 13px;
    color: #F4B644;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
}
.web_cart_col_twenty_one{
    width: 30%;
    height: 40px;
    background-color: #F4B644;
    border-radius: 6px;
}
.web_cart_col_twenty_two{
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding-top: 10px;
}
.web_cart_col_mone{
    padding: 10px;
    width: 100%;
    height: 88px;
    background-color:  rgba(244, 182, 68, 0.25);
    border-radius: 5px;
    border: 1px solid #F4B644;
}
.web_cart_col_mtwo{
    font-size: 16px;
    font-weight: 600;
}
.web_cart_col_mthree{
    font-size: 10px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}
.web_cart_col_mfive{
    padding: 10px;
    width: 100%;
    height: 88px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
}
.web_cart_col_msix{
    font-size: 16px;
    font-weight: 600;
    margin-left: 14px;
    margin-top: 30px;
}