.address_one{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.address_two{
    border-radius: 6px;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    height: 44px;
    cursor: pointer;
}
.address_three{
    font-weight: 500;
    font-size: 12.4555px;
    text-align: center;
    color: #161E40;
    margin-top: 10px;
}
.address_four{
    width: 21px;
    height: 21px;
    margin-top: 11px;
    margin-right: 10px;
}
.address_five{
    width: 100%;
    height: 85px;
}
.web_cart_col_monesss {
    padding: 10px;
    width: 100%;
    background-color: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
    border: 1px solid #F4B644;
}
.web_cart_col_mfivesss {
    padding: 10px;
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #C4C4C4;
}
.web_cart_col_twelves {
    font-size: 13px;
    color: #F4B644;
    font-weight: 600;
    text-align: right;
}