.mob_login_container{
    width: 100%;
    height: 600px;
}
.mob_login_container_two{
    margin-top: 200px;
}
.mob_login_para_one{
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}
.mob_login_container_three{
    width: 100%;
    height: 159px;
    left: 16px;
    top: 225.3px;
    background: rgba(244, 182, 68, 0.25);
    border: 1px solid #F4B644;
    box-sizing: border-box;
    border-radius: 5px;
    background-image: url("../../../assets/img/tikkk.png");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: right top;
    padding: 10px;
}
.mob_login_container_four{
    width: 100%;
    height: 159px;
    left: 16px;
    top: 225.3px;
    background: #EFEFEF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
}
@media (min-width: 0px) and (max-width: 726px){
    .Toastify__toast-container {
        width: 60vw !important;
        padding: 0 !important;
        margin-top: 20px !important;
        left: unset !important;
    }
}
.loas_login{
    padding-left: 23px;
    padding-top: 14px;
}
.mob_log_img{
    margin-top: 20px;
}
.mob_log_p_two{
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-top: 15px;
}
.mob_log_p_three{
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-top: 25px;
}
.mob_login_container_five{
    width: 100%;
    height: 54px;
    right: 16px;
    bottom: 366.7px;
    background: #F4B644;
    border-radius: 5px;
}
.mob_log_p_four{
    font-weight: 500;
    font-size: 16px;
    text-align: right;
    color: #FFFFFF;
    padding-top: 17px;
}
.mob_log_img_one{
    padding-top: 17px;
}
.col_padd{
    padding-left: unset !important;
    padding-right: unset !important;
}
.login_two_img1{
    position: relative;
    display: block;
    margin-left: auto;
    margin-top: -20px;
}
.login_tow_containner_one{
    width: 90%;
    margin: auto;
}
.login_two_img2{
    position: absolute;
    top: 158px;
    left: 16px;
}
.login_two_img3{
    position: absolute;
    top: 208px;
    left: 16px;
    font-weight: 500;
    font-size: 20px;
    color: #161E40;
}
.log_two_span{
    color: #F4B644;
}

.login_two_img400{
    padding-top: 10px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #3A3C3F;
    margin-bottom: 3px;
}
.login_two_img4{
    padding-top: 40px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #3A3C3F;
    margin-bottom: 3px;
}
.lo_tw_one{
    background: #EAECEE;
    border-radius: 5px;
    width: 100%;
    height: 50px;
}
.lo_tw_two{
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #424D59;
    padding:13px;
}
.otp_img {
    margin: auto;
    display: block;
    padding-bottom: 26px;
    padding-top: 26px;
}
.lo_tw_three{
    margin-top: 2px;
    background-color: #EAECEE !important;
    border-color: #EAECEE !important;
}
.lo_tw_foue{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #161E40;
    padding-top: 30px;
}
.lo_tw_five{
    margin-top: 20px;
    width: 157.5px;
    height: 50px;
    left: calc(50% - 157.5px/2 + 88.75px);
    top: calc(50% - 50px/2 - 16.35px);
    background: #F4B644;
    border-radius: 5px;
}
.lo_tw_six{
    width: 100%;
    margin-left: auto;
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    width: 30px;
    height: 30px;
}
.lo_tw_seven{
    width: 30px;
    height: 30px;
    margin-top: 12px;
}
.lo_tw_eight{
    text-align: center;
    font-weight: 500;
    font-size: 12.2378px;
    color: #000000;
    margin-top: 5px;
}
.lo_tw_nine{
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 292.66px;
    height: 45.81px;
    left: 39.17px;
    top: 493.05px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1.83567px rgba(0, 0, 0, 0.084), 0px 1.22378px 1.83567px rgba(0, 0, 0, 0.168);
    border-radius: 6.11889px;
}
.lo_tw_ten{
    width: 20px;
    height: 20px;
}
.lo_tw_elevan{
    margin-left: 7px;
    margin-top: 13px;
    font-weight: 500;
    font-size: 12.2378px;
    color: rgba(0, 0, 0, 0.54);
}
.lo_tw_twelve{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #9A9A9A;
}
.login_two_img233{
    position: relative;
    margin-right: auto;
    margin-top: 60px;
    transform: rotate(180deg);
}
.lo_tw_thirteen{
    border-width: 2px;
    border-style: dashed;
    border-color: #EAECEE;
}
.lo_tw_five5{
    margin-top: 20px;
    width:100%;
    height: 50px;
    left: calc(50% - 157.5px/2 + 88.75px);
    top: calc(50% - 50px/2 - 16.35px);
    background: #F4B644;
    border-radius: 5px;
}
.hiiiiiii input{
    border-top-color: #ffffff00;
    border-right-color: #ffffff00;
    border-left-color: #ffffff00;
    border-bottom-color: black;
    border-bottom-width: 1px;
    background: unset !important;
    padding-right: 10px;
}
.timerr{
    margin: auto;
    width: 250px;
    padding: 10px;
}
.ti_p_one{
    text-align: center;
    padding-top: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #737362;
}
.ti_p_two{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #F4B644;
}
.ti_p_four{
    padding-top: 18px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
.ti_p_five{
    margin-top: 15px;
    width: 30px;
    height: 30px;
}