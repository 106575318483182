body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.leaflet-container{
  height: 300px;
  width: '100%';
}
.leaflet-control-attribution {
  display: none !important;
}
.request-popupss{
  bottom: 26px !important;
}

@media (min-width: 726px) and (max-width: 992px) {

.whyycontainerrr{
  height: 980px !important;
}
}

#inputID::placeholder {
  color: #ff0000;
  opacity: 1;
}
.css-1c6j008:focus, .css-1c6j008[data-focus] {
  box-shadow: unset !important;
}
.ReactModal__Content{
  height: auto !important;
}
.hjkmhkhjk .icon-loading {
  margin-left: unset !important;
}
#lblCartCountsss {
  position: absolute;
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: 11px;
  margin-top: -6px;
}
#lblCartCountsssvvvv {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  margin-top: -10px;
}
#lblCartCountsssdd {
  position: absolute;
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: 32px;
  margin-top: -6px;
}
.grrrasdfsfgsgs{
  border: 4px solid green ;
}
.reddddsdfsfgsgs{
  border: 4px solid red ;
}
.elsesdfsfgsgs{
  border: 4px solid #F4B644 ;
}
.zdfsdfsfdffesfgsgs{
  border: 4px solid #cfcf00 ;
}