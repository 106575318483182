.prodett1{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #676767;
}
.prodett2{
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: #161E40;
    margin-top: -5px;
}
.prodett3{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.det_div{
    margin-top: 20px;
}
.prodett4{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}