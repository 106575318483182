.run_ord_six_hand {
    margin-top: 5px !important;
    font-weight: 600;
    font-size: 13px;
    color: rgba(22, 30, 64, 0.5);
}
.run_ord_sevens_hand {
    /* margin-top: -8px; */
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #161E40;
}
.run_ord_sevens_hands {
    margin-top: 18px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #161E40;
}
.run_ord_sevens_handss {
    color: #F4B644;
    margin-top: 18px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
}
.new_bordererere{
    border-top: 5px solid #F4B644;
    border-radius: 6px;
}