.bank_det_one{
    width: 100%;
    height: 44px;
    background: #161E40;
    border-radius: 5px;
}
.bank_det_two{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}
.mob_wi_one{
    padding: 10px;
    width: 100%;
    /* height: 130.17px; */
    background: #F4F4F4;
    border-radius: 10px;
}
.mob_wi_two{
    font-weight: 600;
    font-size: 15px;
    color: rgba(22, 30, 64, 0.5);
}
.mob_wi_three{
    margin-top: -15px;
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.mob_wi_four{
    margin-top: -15px;
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}
.mob_wi_threes{
    font-weight: 600;
    font-size: 19px;
    color: #161E40;
}
.mob_wi_fours{
    font-weight: 500;
    font-size: 14px;
    color: #676767;
}
.mob_wi_five{
    text-align: center;
    margin-top: 10px;
    font-weight: 900;
    font-size: 14px;
    color: #161E40;
}
.mob_wi_six{
    margin-top: 5px;
    width: 100%;
    height: 32px;
    background: #161E40;
    border-radius: 3px;
}
.mob_wi_seven{
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 11px;
    color: #FFFFFF;
}
.mob_wi_eight{
    margin-top: 6px;
    width: 20px;
    height: 20px;
}
.mob_wi_nine{
    opacity: 0.1;
}
.noti_one{
    padding: 10px;
    width: 100%;
    height: 82px;
    background: #F4F4F4;
    border-radius: 5px;
}
.noti_two{
    font-weight: 600;
    font-size: 14px;
    color: #161E40;
}
.noti_three{
    font-weight: 600;
    margin-top: -10px;
    font-size: 12px;
    line-height: 18px;
    color: #5B5B5B;
}
.noti_four{
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.css-hxtskf{
    width: 80% !important;
    margin: auto !important;
}
.supp_one{
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    color: #6CC8BE;
}
.supp_two{
    padding: 15px;
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
    position: fixed;
    right: 0;
    left: 0;
    bottom: -2px !important;
}
.kyc_in_ones{
    margin-top: -7px !important;
    background-color: #f4f4f4 !important;
}

