.zoom {
    position: relative;
    &__loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255,255,255,.3);
      display: none;
    }
  }