.run_ord_threeb {
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
}
.service_twob {
    padding: 15px;
    width: 100%;
    height: 102px;
    background: #F4F4F4;
    border-radius: 5px;
}
.service_fourb {
    margin-top: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    color: #000000;
}
.service_threeb {
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
    margin-top: 8px;
}
.service_sixb {
    margin-top: 27px;
    width: 100%;
    height: 28px;
    background: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
}
.service_sixsb {
    margin-top: 27px;
    width: 100%;
    height: 28px;
    background: #F4B644;
    border-radius: 5px;
}
.service_sixsgreen{
    margin-top: 27px;
    width: 100%;
    height: 28px;
    background: #008000;
    border-radius: 5px;
}