.mob_foot{
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
    height: 72px;
    right: 0px;
    bottom: 0px;
    background-color: #161E40;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}
.bbtn{
    padding: 10px;
    width: 132px;
    height: 44px;
    left: 227px;
    top: 14px;
    background: #F4B644;
    border-radius: 5px;
    margin-top: 9px;
    margin-left: auto;
}
.compara{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 4px;
}
.kaart{
    width: 24px;
    height: 24px;
    margin-left: 3px;
}
.compara1{
    width: 93px;
    height: 17px;
    left: 27px;
    top: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    margin-top: 13px;
}
.compara2{
    width: 53px;
    height: 17px;
    left: 47px;
    top: 17px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
}
.second-top{
    height: 50px;
    background-color: #F4F4F4;
}
.slliderr{
    height: 210px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: 233px 186px;
}
.propara{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: rgba(22, 30, 64, 0.5);
}
.propara2{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 1px; */
    color: #161E40;
    margin-bottom: 15px !important;
}
.prppara3{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #676767;
}
.propara4{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #161E40;
}
.pro_cont{
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    top: 0px;
    background: #161E40;
    border-radius: 5px;
}
.pro_cont1{
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    top: 0px;
    border-width: 2px;
    border-style: solid;
    border-color: #161E40;
    border-radius: 5px;
}
.propara5{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #161E40;
    white-space: nowrap;
    margin-top: 3px;
}
.propara6{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
    margin-top: 3px;
}
.propara7{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    color: #000000;
}
.list{
    list-style-type: circle;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-top: 6px;
    margin-left: 16px;
}
.propara8{
    margin-top: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
}
.propara9{
    width: 20px;
    height: 20px;
}
.dassh{
    opacity: 0.2;
    border: 2px dashed #161E40;
}
.propara10{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #161E40;
}
.react-date-picker__wrapper{
    height: 46px;
    background-color: white !important;
    border: thin solid #e2e8f0 !important;
    padding: 5px;
    border-radius: 5px;
}
.datepick{
    /* width: 30px; */
}
.propara12{
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #000000;
    margin-left: 5px;
    margin-top: 10px !important;
}
.cool2{
    width: 167px;
    height: 173px;
    left: 16px;
    top: 842.44px;
    background: #F4F4F4;
    border: 1px solid #EAECEE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 14px 24px;
}
.propara13{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 5px;
}
.propara14{
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    color: #161E40;
    text-align: center;
}
.propara15{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000;
    margin-Top: 10px;
}
.propara16{
    font-weight: 600;
    font-size: 10.6523px;
    line-height: 13px;
    color: #000000;
    margin-top: 6px;
    margin-left: 4px;
}
.propara17{
    padding-left: 15px;
    padding-top: 14px;
}