.product-grid{
    position: relative;
    background-color: #fff;
    font-family: 'Lato', sans-serif;
    text-align: left;
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 7px 0px;
}
.product-grids{
    padding-bottom: 30px;
    height: 315px;
    width: 315px;
    position: relative;
    background-color: #fff;
    font-family: 'Lato', sans-serif;
    text-align: left;
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 7px 0px;
}
@media (min-width:726px) and (max-width: 1100px) {
    .product-grids{
        height: 270px;
        width: 270px;
    }
}
@media (min-width:1100px) and (max-width: 1200px) {
    .product-grids{
        height: 200px;
        width: 200px;
    }
}
@media (min-width:1200px) and (max-width: 1300px) {
    .product-grids{
        height: 200px;
        width: 200px;
    }
}
@media (min-width:1300px) and (max-width: 1500px) {
    .product-grids{
        height: 200px;
        width: 200px;
    }
}
@media (min-width:1500px) and (max-width: 1650px) {
    .product-grids{
        height: 221px;
        width: 221px;
    }
}
@media (min-width:1650px) and (max-width: 1950px) {
    .product-grids{
        height: 270px;
        width: 270px;
    }
}

.product-grid .product-image{ position: relative; }
.product-grid .product-image a.image{ display: block; }
.product-grid .product-image img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 73%;
    height: auto;
    margin: auto;
}
.product-grids .product-image{ position: relative; }
.product-grids .product-image a.image{ display: block; }
.product-grids .product-image img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: auto;
}
/* .product-grid:hover .product-image .pic-1{
    opacity: 0;
    transition: all 0.5s ease 0s;
}
.product-image .pic-2{
    width: 100%;
    height: 100%;
    filter: blur(10px);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease 0s;
} */
.product-grid:hover .product-image .pic-2{ filter: blur(0);opacity: 1; }
.product-grid .product-like-icon{
    color: #F4B644;
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 10px;
    transition: all 0.3s ease 0s;
}
.product-grids .product-like-icon{
    color: #C4C4C4;
    font-size: 16px;
    position: absolute;
    right: 13px;
    top: 10px;
    transition: all 0.3s ease 0s;
}
/* .product-grid .product-like-icon:hover{ text-shadow: 2px 2px 0 rgba(0,0,0,0.5); } */
.product-grid .product-links {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    z-index: 1;
    transition: all 0.3s ease;
}
.product-grids .product-links {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    z-index: 1;
    transition: all 0.3s ease;
}
.product-detss {
    margin-bottom: 5px;
    font-size: 10px;
    margin-left: 3px;
}
.product_detss_box:hover{
    color: white !important;
}
.product_detss_box{
    background-color:#161E40 !important;
    color: white !important;
    font-size: 10px !important;
}
.product-grid:hover .product-links {
    opacity: 1;
    transform: translateX(-50%) scale(1);
}
.product-grid .product-links li{ display: inline-block; }
.product-grid .product-links li a{
    color: #fff;
    background: #333;
    font-size: 16px;
    line-height: 38px;
    width: 40px;
    height: 40px;
    display: block;
    transition: all 0.3s ease 0s;
}
.product-grid .product-links li a:hover{
    color: #fff;
    background: #F4B644;
}
.product-grid .product-content{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
}


.product-grids .product-links li{ display: inline-block; }
.product-grids .product-links li a{
    color: #fff;
    background: #333;
    font-size: 16px;
    line-height: 38px;
    width: 40px;
    height: 40px;
    display: block;
    transition: all 0.3s ease 0s;
}
.product-grids .product-links li a:hover{
    color: #fff;
    background: #F4B644;
}
.product-grids .product-content{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease 0s;
    bottom: 40px;
}



.product-grid:hover .product-content{ 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.product-grid:hover .lit{ 
    /* margin-top: -40px !important; */
}
.product-grid .lit{
    /* margin-top: 40px !important; */
}
.product-grid .title{
    text-align: left !important;
    font-size: 14px;
    margin-left: 10px;
    text-transform: capitalize;
}
.product-grid .title a{
    color: #222;
    transition: all 0.3s ease 0s;
}
.product-grid .title a:hover{
    font-size: 14px;
    color: #F4B644;
}
.product-grid .price{
    text-align: left !important;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 600;
}

.product-grid .lit{
    /* margin-top: 40px !important; */
}
.product-grids .title{
    text-align: center !important;
    font-size: 14px;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 600;
}
.product-grids .title a{
    color: #222;
    transition: all 0.3s ease 0s;
}
.product-grids .title a:hover{
    font-size: 14px;
    color: #F4B644;
}
.product-grids .price{
    text-align: left !important;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 600;
}


@media screen and (max-width:990px){
    .product-grid{ margin: 0 0 30px; }
}
/* .product-grid:hover .product-det {
    margin-left: 5px;
    top: 97%;
    display: block;
    opacity: 1;
} */
.product-grid:hover  {
 /* border-radius: 10px; */
  /* border-style: solid;
  border-width: 2px; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 20 !important;
  overflow-x: hidden; */
}
.head{
    width: 100%;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    top: calc(100% + 10px);
    z-index: 1;
    transition: all 0.3s ease;
}
/* .product-grid:hover .head {
    margin-top: 15px;
    margin-left: 15px;
    text-decoration: underline;
    transform: translateY(-50%);
    opacity: 1;
    transform: translateY(-50%) scale(1);
} */
.product-linkss{
    display: none;
    width: 60px;
    height: 60px;
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease;
}
/* .product-grid:hover .product-linkss {
    margin-left: 10px;
    
    transform: translateY(-50%);
    display: block;
    opacity: 1;
    transform: translateY(-50%) scale(1);
}
.product-grid:hover .pro {
    
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
} */
.detailpara{
  background-color: lightgrey;
  width: 57px;
  height: 18px;
  margin-top: 5px;
  /* border-style: solid;
  border-width: 1px; */
  border-radius: 4px;
}
.detailpara p{
    font-size: 10px;
    margin-bottom: 3px !important;
}
/* .product-grid:hover .rowss{
    display: table;
    width: 100%; 
    table-layout: fixed; 
    border-spacing: 10px; 
}
.product-grid:hover .columnnn{
    display: table-cell;
} */
.rowss{
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: 10px; /*Optional*/
}
.columnnn{
    display: table-cell;
}
.paragraph{
    font-size: 10px;
    margin-left: 10px;
}
.cont{
    margin-top: -20px;
}
.heads{
    display: none;
    width: 100%;
    list-style: none;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    top: calc(100% + 10px);
    z-index: 1;
    transition: all 0.3s ease;
}
/* .product-grid:hover .heads{
    display: block;
    margin-left: 15px;
    transform: translateY(-50%);
    opacity: 1;
    transform: translateY(-50%) scale(1);
}
.product-grid:hover .onepice {
    padding: 10px;
} */
.hat-hover{
    position: absolute;
    right: 13px;
    top: 10px;
    transition: all 0.3s ease 0s;
}
.button-35 {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-weight: 700;
    justify-content: center;
    line-height: .5;
    margin: 0;
    outline: none;
    padding: 1rem 1.2rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s,-webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-35:hover {
    box-shadow: #F4B644 0 0 0 3px, transparent 0 0 0 0;
}
  
.button-55 {
    font-size: 14px;
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    line-height: 23px;
    outline: none;
    padding: .45rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
.button-55:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
}
.button-55:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}
.scrooooll{
    
}

@media (min-width:1400px) and (max-width: 1720px) {
    .product_detss_box{
        font-size: 8px !important;
        padding-top: 7px !important;
    }
}


.arrow {
    width: 15px; 
    height: 23px; 



  }
  
  