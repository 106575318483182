.checklist_one{
  padding: 10px 16px 15px;
  width: 100%;
  height: 69px;
  background: #FFFFFF;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
  right: 0px;
  bottom: 0px;
  position: fixed;
  left: 0;
}
.checklist_two{
  padding: 10px;
  width: 100%;
  height: 44px;
  background: #F4B644;
  border-radius: 5px;
}
.checklist_three{
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
}
.ch_one{
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.ch_two{
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: #000000;
}
.ch_three{
  width: 15px;
  height: 15px;
  border: 0.83318px solid #C4C4C4;
  border-radius: 1.66636px;
  margin-top: 4px;
  margin-right: 3px;
}
.ch_threes{
  align-items: center;
  padding: 13px 10px 13px 15px;
  width: 100%;
  height: auto;
  background: #F4F4F4;
  border-radius: 5px;
}
.ch_six{
  width: 18px;
  height: 18px;
  border: 1px solid #C4C4C4;
  border-radius: 2px;
  margin-top: 19px;
}
.ch_seven{
  font-weight: 600;
  font-size: 12px;
  color: #161E40;
}
.ch_eight{
  margin-top: -18px;
  font-weight: 500;
  font-size: 8px;
  color: #000000;
  line-height: 12px;
}
.qrs{
  width: 25px;
  height: 20px;
  margin-top: 8px;
}
.ckeck_veri{
  padding: 10px 16px 15px;
  width: 100%;
  height: 69px;
  background: #FFFFFF;
  right: 0px;
  bottom: 0px;
  position: fixed;
  left: 0;
}
.ckeck_veri_one{
  width: 133px;
  height: 44px;
  background: #F4B644;
  border-radius: 5px;
}
.check_ver_two{
  margin-left: 40px;
  text-align: center;
  color: white;
  padding-top: 10px;
}
.check_ver_three{
  width: 24px;
  height: 24px;
  margin-top: 10px;
}
.chk_veri_one{
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: auto;
  overflow-y: unset;
}
.chk_veri_two{
  width: 215px;
  height: 75px;
  border-color: #161E40;
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
}
.chk_veri_three{
  padding-top: 25px;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.5em;
  color: #424D59;
}
.delivered{
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #161E40;
}
.red_minuss{
  margin-top: -48px;
  position: relative;
  margin-left: 13px;
}
.web_pending_ones{
  margin-left: auto;
  margin-top: 6px;
  height: 80%;
}
.ch_eights{
  font-weight: 500;
    font-size: 8px;
    color: #000000;
    line-height: 12px;
}