.his_one{
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: #F4B644;
}
.his_two{
    margin-top: -15px;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #9A9A9A;
}
.his_three{
    position: absolute;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #161E40;
}
.his_four{
    width: 100%;
    height: 39.97px;
    background: #F4B644;
    border-radius: 5px;
}
.his_five{
    width: 100%;
    height: 39.97px;
    background: #008000;
    border-radius: 5px;
}
.his_six{
    width: 100%;
    height: 39.97px;
    background: #C8202D;
    border-radius: 5px;
}
.his_seven{
    color: #008000 !important;
}
.his_eight{
    color: #C8202D !important;
}