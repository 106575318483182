.login_two_img10{
    position: relative;
    display: block;
    margin-left: auto;
    margin-top: -20px;
    z-index: -1;
}
.profile_img_one{
    margin-top: 20px;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.profile_img_two{
    align-items: center;
    position: absolute;
    padding: 0px 5px;
    width: 114px;
    height: 28px;
    left: 148px;
    top: 110.5px;
    background: #F4B644;
    border-radius: 8px;
}
.profile_p_one{
    padding-top: 2px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
}
.profile_img_three{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    position: relative;
    color: #F4B644;
    /* left: 148px; */
    /* top: 170.5px; */
    /* matgin-top: -50px; */
    margin-top: -95px;
}
.profile_img_four{
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    /* position: absolute; */
    color: #676767;
    /* left: 148px; */
    /* top: 190.5px; */
}
.profile_img_five{
    align-items: center;
    margin: auto;
    padding: 0px 5px;
    width: 108px;
    height: 35px;
    left: 148px;
    top: 230.5px;
    background: #161E40;
    border-radius: 3px;
}
.profile_img_six{
    padding: 10px;
    margin-top: 9px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
}
.profile_img_seven{
    position: absolute;
    padding: 30px;
    width: 100%;
    height: 783px;
    left: 0px;
    top: 285.49px;
    background: #FFFFFF;
    border-radius: 25px 25px 0px 0px;
}
.profile_img_nine{
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.profile_img_ten{
    /* width: 25px;
    height: 25px; */
}
.ed_pro_one{
    z-index: -1;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.ed_pro_two{
    cursor: pointer;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    align-items: center;
    padding: 0px 5px;
    width: 114px;
    height: 28px;
    background: #F4B644;
    border-radius: 8px;
}
.ed_pro_three{
    padding-top: 2px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
}
.ed_pro_four{
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 0px 5px;
    width: 115px;
    height: 35px;
    background: #161E40;
    border-radius: 3px;
}
.ed_pro_five{
    padding-top: 5px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}
.ed_pro_six{
    border: 1px solid #F4B644;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 64px;
    left: 15.83px;
    top: 482.31px;
    box-sizing: border-box;
    border-radius: 5px;
}
.ed_pro_sevens{
    font-weight: 500;
    font-size: 14px;
    color: #F4B644;
    text-align: center;
    padding-top: 9px;
}
.ed_pro_seven{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    color: #3A3C3F;
}
.ed_pro_enght{
    padding: 10px 15px 10px 10px;
    width: 100%;
    height: 92px;
    background: rgba(244, 182, 68, 0.25);
    border: 1px solid #F4B644;
    box-sizing: border-box;
    border-radius: 5px;
}
.ed_pro_nine{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #000000;
}
.ed_pro_ten{
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
}
.ed_pro_eleven{
    margin-right: auto;
    margin-left: auto;
    margin-top: 16px;
    width: 55px;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}
.ed_pro_twelve{
    padding-top: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.005em;
    color: #F4B644;
}
.ed_pro_thirteen{
    padding: 10px 15px 10px 10px;
    width: 100%;
    height: 92px;
    background: #EAECEE;
    border-radius: 5px;
}
.ed_pro_fourteen{
    border: 1px solid #474747;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    height: 64px;
    left: 15.83px;
    top: 482.31px;
    box-sizing: border-box;
    border-radius: 5px;
}
.ed_pro_fifteen{
    font-weight: 500;
    font-size: 12.4555px;
    text-align: center;
    color: #161E40;
}
.ed_pro_sixteen{
    width: 20px;
    height: 20px;
}
.ed_pro_seventeen{
    text-align: center;
    padding: 10px 20px;
    width: 100%;
    height: 54px;
    background: #F4B644;
    border-radius: 5px;
}
.ed_pro_eighteen{
    padding-top: 7px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
}
.new_add_one{
    padding-left: 15px;
    padding-top: 20px;
    padding-right: 20px;
    width: 100%;
    height: 54px;
    background: #EAECEE;
    border-radius: 5px;
}
.new_add_two{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #424D59;

}
.new_add_three{
    display: block;
    margin-left: auto;
}
.kyc_cont_two_upload_threess{
    opacity: 0;
    /* position: relative; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.ed_pro_three{
    margin-top: -46px;
}