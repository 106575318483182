  .product-detss{
    width: auto;
    height: 25px;
  }
  .transparent-bar {
    position: fixed !important;
    background: white;
    /* box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1); */
  }
  .hrt_red_clr{
    color: red;
  }
  .transparent-barss {
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    position: fixed !important;
    background: white;
  }
  @media only screen and (max-width: 1130px) {
    .main-menu nav ul li {
      padding: 0 5px !important;
  }
  .main-menu nav ul li > a {
    font-size: unset !important;
  }
  .hdddimggg{
    width: 105px !important;
  }
  }

  /* @media (min-width: 1600px) and (max-width: 1840px){
    .home_pro_cont{
      height: 450px !important;
    }
}

@media (min-width: 1400px) and (max-width: 1600px){
  .home_pro_cont{
    height: 400px !important;
  }
}

@media (min-width: 992px) and (max-width: 1260px){
  .home_pro_cont{
    height: 420px !important;
  }
} */
.web_cat_two{
  display: block;
}
.welcome-area{
  padding: 70px;
  background-color: whitesmoke;
}
.bro_desss_pro{
  border-style: solid !important;
  color: black !important;
  font-size: 10px !important;
  border-width: 2px !important;
  border-color: black !important;
}
.pop_head_tit{
  font-size: 25px;
    font-weight: 500;
    margin-top: 2%;
}
  
  @media only screen and (max-width: 888px) {
    .header-right-wrap .same-style {
      margin-left: 5px !important;
    }
  }
  @media only screen and (max-width: 1550px) {
    .fdxx{
      width: 218px !important;
      height: 52px !important;
    }
    .fdssa{
      padding-top: 16px !important;
      font-size: 16px !important;
    }
    .fdddxx{
      margin-top: 16px !important;
      width: 25px !important;
      height: 25px !important;
    }
    .imgfdd{
      padding-top: 3px !important;
      width: 20px;
    }
  }

  @media only screen and (min-width: 1130px) {
     .main-menu nav ul li > a {
      font-size: 13px !important;
    }
  }


  .btn{
    padding-top: 5px !important;
  }
 

  .product-grid .price {
    font-size: 16px !important;  
  }
  .product-grid{
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;  */
    overflow-y: hidden;
    overflow-x: hidden;
  }


  
  @media (min-width:1400px) and (max-width: 1720px) {
    .btn{
      padding-top: 7px !important;
    }
    .bro_desss_pro {
      font-size: 7px !important;
    }
    /* .product-grid .price {
      font-size: 9px !important;
  } */
  .dcnskjbvjksbkv{
    font-size: 8px !important;
  }
}
.navbar {
    border-bottom: solid 1px #EBEBEB;
  }
  .bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
  }
  .nav-link,
  .bottom-nav-link {
    color: #55575b;
  }
  .header-hm9333{
    box-shadow: rgb(192 192 192 / 45%) 0px 25px 20px -20px;
  }
  .header-top-areas{
    border-bottom: unset !important;
    padding: 19px 0 !important;
  }
  .product-grid .product-content{
    height: 100px;
  }
  /* .product-grid .product-image img {
    padding: 50px ;
  } */
  .main-menu nav ul li > a {
    color: #000000 !important;
    font-weight: 600 !important;
  }
  .main-menu nav ul li {
    padding: 0 25px ;
  }
  .header-right-wrap .same-style {
    margin-left: 40px ;
    margin-top: 7px;
  }
  .header-right-wrap .same-style > a {
    color: #585858 !important;
  } 
  .header-right-wrap .same-style {
    color: #585858 !important;
  }
  .thr_one{
    margin-left: auto;
    margin-right: auto;
    padding: 6px;
    width: 140px;
    height: 40px;
    border: 1px solid #161E40;
    background-color: white;
    box-shadow: 0px 0px 19.5347px 1.95347px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  .thr_two:hover {

    /* -ms-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);  */
  }
  .thr_two:hover ~ .thr_three {
    /* display: none */
  }
  .thr_two{
    /*height: 70px;*/
    display: block;
    margin: auto;
    width: 200px;
    /* margin-top: 10px; */
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  }
  .thr_three{
    padding-bottom: 10px;
    text-align: center;
    font-weight: 600;
    padding-top: 3px;
  }

  .slider-content-17 p{
    /*font-size: 75px !important;*/
    font-size: 4vw !important;
    line-height: normal;
    font-weight: 600;
    color: #000000;
  }
  .slider-content-17 h1{
    margin-top: 40px;
    font-size: 25px !important;
    font-weight: 600;
    color: #000000;
  }
  .slider-btn-9{
    border-radius: 60px;
  }
  /* .slider-content-17 .slider-btn-19 a {
    border-radius: 60px;
    padding: 26px 69px 26px !important;
    font-size: 26px !important;
    text-transform: capitalize !important;
    color: white !important;
    background-color: #161E40;
  }
  .slider-content-17 .slider-btn-19 a:hover {
    color: white !important;
    border: unset !important;
  }
  */
  .slider-btn-19{
    margin-top: 50px;
  } 

  .fdxx{
    margin-top: 30px;
    border-radius: 60px;
    text-transform: capitalize !important;
    color: white !important;
    background-color: #161E40;
    width: 350px;
    height: 100px;
  }
  .fdddxx{
    margin-top: 34px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #F4B644;
    display: block;
    margin-right: auto;
  }
  .fdssa{
    padding-top: 30px;
    text-align: right;
    font-size: 26px ;
  }
  .imgfdd{
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8px;
  }

  .bg-glaucous {
    background-color: #ffffff !important;
  }
  .header-top-area {
    border-bottom: unset !important;
    padding: 10px 0 !important;
  }

  .product-grid .product-like-icon{
    color: #a8a8a8 !important;
  }
  .product-grids .product-like-icons{
    color: #C8202D !important;
  }
  .lllooo{
    position: fixed !important;
    bottom: -2px;
    left: -1px;
  }

  .css-1x0ba6n:active{
    cursor: grabbing;
  }

  .bottom-nav-link.active {
    color: #922c88;
  }
  .bottom-tab-label {
    font-size: 12px;
  }.navbar {
    border-bottom: solid 1px #EBEBEB;
  }
  .bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
  }
  .nav-link,
  .bottom-nav-link {
    color: #55575b;
  }
  .bottom-nav-link.active {
    /* border-top: 1px solid #e6ce00;
    border-radius: 6px;
    color: #922c88; */
  }
  .bottom-tab-label:active {
    color: #F4B644;
  }
  .booot{
      background-color: #ffffff !important;
  }
  .loabb{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
    position: static;
    padding-top: 5px;
  }
  .fixed-bottom{
    bottom: -2px !important;
  }
  .nav_mgg{
    width: 30px;
    height: 30px;
  }
  .bo{
    position: absolute;
    width: 35px;
    height: 5px;
    left: 0px;
    top: 0px;
    
    background: #F4B644;
    border-radius: 0px 0px 10px 10px;
  }
  .tooot {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    height: 50px;
  }
  .loab{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
    padding-top: 5px;
  }
  .imglogo{
    position: static;
    /* width: 84.55px;
    height: 20px; */
    left: 0px;
    top: 2px;
  }
  .paddd{
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
  }
  .cityname{
    position: static;
    width: 60px;
    height: 17px;
    left: 0px;
    top: 3.5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: right;
    color: #000000;
  }
  .gbs{
    width: 24px;
    height: 24px;
    left: 0px;
    top: 0px; 
  }
.slider-height-6{
    /* margin-top: 40px; */
    height: 200px !important;
}
.radiouss{
    border-radius: 6px;
}
.radi{
    background-image: url('https://media.istockphoto.com/photos/computer-display-with-blank-white-screen-ncomputer-monitor-isolated-picture-id1183337149');
}
.heaone{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px !important;
line-height: 15px !important;
color: #FFFFFF !important;
}
.swiper-pagination-bullet{
    width: 5px !important;
    height: 5px !important;
}
.btton{
    color: #ffffff !important;
    border: 1px solid #fff !important;
    padding: 10px 20px 10px !important;
}
.pra_groub{
    padding-top: 10px !important;
}
.pro_groub_head{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.imageee{
  padding-top: 10px;
  display: block;
  margin: auto;
  /* width: 60px; */
  top: 27.28px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.ppp{
  color: #000000;
  font-size: 12px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 500;
}
.ppps{
  color: #161E40;
  font-size: 12px;
  text-align: right;
  font-family: 'Poppins';
  font-weight: 500;
  margin-right: 5px;
  margin-top: 3px;
}
.imggs{
  width: 25px;
  height: 25px;
  margin-top: 3px;
  margin-right: 20px;
}
.css-ehce6f{
  width: 138.1px !important;
  height: 215.55px !important;
  left: 16.09px;
  top: 0px;
  background: #EDEFF1;
  border-radius: 5px;
}
.css-1olnuck{
  width: 138.1px !important;
  height: 215.55px !important;
}
.css-yscfzs{
  width: 138.1px !important;
  height: 215.55px !important;
}
.css-1ak5z7s{
  width: 138.1px !important;
  height: 215.55px !important;
}
.css-ycvz1n{
  width: 138.1px !important;
  height: 215.55px !important;
}
.pop_image{
  margin: auto;
  left: 4.31px;
  top: 0.91px;
}
.pop_small_head{
  width: 25px;
  height: 10px;
  left: 0px;
  top: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
}
.pop_small_head2{
  margin-top: -7px;
  width: 110px;
  left: 0px;
  top: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}
.pop_small_head3{
  width: 111.94px;
  height: 24px;
  left: 0px;
  top: 33px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
}
.pop_button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 78px;
  height: 24px;
  left: 0.15px;
  top: 0.1px;
  background: #161E40;
  border-radius: 3px;
  margin-bottom: 5px;
}
.pop_icon{
  width: 22px;
  height: 20px;
  left: 7%;
  right: 7.01%;
  top: 12.5%;
  bottom: 12.5%;
  box-sizing: border-box;
}
.backgro{
  width: 100%;
  height: 150px;
  left: 0px;
  top: 0px;
  background: linear-gradient(153.42deg, #161E40 -16.27%, #00132B 126.64%);
  border-radius: 5px;
}
.backgro1{
  width: 100%;
  height: 150px;
  left: 0px;
  top: 0px;
  background: linear-gradient(159.42deg, #E32D36 -67.35%, #C8202D 112.93%);
  border-radius: 5px;
}
.backgro2{
  width: 100%;
  height: 150px;
  left: 0px;
  top: 0px;
  background: linear-gradient(164.21deg, #FF9901 1.41%, #F4B644 112.43%);
  border-radius: 5px;
}
.img1{
  left: 1.29px;
  top: -5.7px;
  font-style: normal;
  font-weight: 600;
  font-size: 87.7057px;
  line-height: 107px;
  color: #FFFFFF;
  opacity: 0.5;
}
.offer_para1{
  text-align: right;
  height: 41px;
  left: 11.34px;
  top: 33.12px;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  color: #FFFFFF;
  margin-top: -50px;
  margin-left: 10px;
}
.offer_para2{
  width: 30px;
  height: 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  transform: rotate(-90deg);
  margin-top: -40px;
  text-align: left;
  margin-left: -6px;
}
.offer_para3{
  width: 74px;
  height: 30px;
  left: 18.67px;
  top: 107.72px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
  margin: auto;
  padding-top: 40px;
}
.middd{
  font-style: normal;
  font-weight: 500;
  font-size: 12.7087px;
  line-height: 15px;
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
}
.last_cont{
  width: auto;
  height: auto;
  left: 16.67px;
  top: 1001.08px;
  background: #161E40;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin: auto;
  padding: 12px;
}
.imggg{
  width: 149.96px;
  height: 188.59px;
  left: 27.61px;
  top: 1013.06px;
  border-radius: 5px;
}
.last_p{
  font-weight: 900;
  font-size: 16px;
  color: #FFFFFF;
}
.last_p1{
  height: 12px;
  left: 188.17px;
  top: 1040.17px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #A8A8A8;
}
.last_p3{
  width: auto;
  height: 30px;
  left: 188.17px;
  top: 1057.17px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}
.last_p4{
  width: auto;
  left: 187.67px;
  top: 1097.41px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #B3B3B3;
  text-align: justify;
}
.last_p5{
  width: 62px;
  height: 13px;
  left: 188.17px;
  top: 1187.68px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-decoration-line: underline;
  color: #F4B644;
}
.homeli{
  font-size: 15px;
  color: #555252;
  font-weight: 500;
  letter-spacing: .8px;
  display: inline-block;
}
.shopproduct{
  color: #242424;
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.shopproduct:hover{
  color: #F4B644;
}
.threeimg{
  margin-left: auto;
  margin-right: auto;
  width: 230px !important;
  height: 247px !important;
}
.threeCollection{
  margin-left: auto;
  border-radius: 6px;
  width: 100px;
  height: 40px;
  background-color: #F4B644;
  color: white;
}
.threeCollectionp{
  color: white;
}
.collection-product-2{
  background-color: #f6f6f6;
  padding: 30px;
  border-radius: 6px;
}
.cart_checkout{
  color: white;
  margin-top: 15px;
}
.thr_one_skl{
  width: 100%;
  height: 200px;
  background-color: white;
  /* box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; */
  border-radius: 10px;
}
.thr_one_sklsss{
  width: 100%;
  height: 100px;
  /* box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; */
  border-radius: 10px;
}
@media (min-width: 1200px){
  .head_container {
    max-width: 1800px !important;
  }

  .head_containers {
    max-width: 1945px !important;
  }
}
.head_containersss {
  max-width: 1540px !important;
}
.home_four_para{
  font-weight: 600;
  font-size: 16px;
  color: #161E40;
  text-align: center;
  margin-top: 15px;
}
.home_four_para_two{
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: justify; 
  color: rgba(0, 0, 0, 0.5);
  padding : 20px;
}
.home_transform_rotate{
  -ms-transform: rotate(-90deg); /* IE 9 */
  transform: rotate(-90deg);
}
.showsave{
  transform: rotate(180deg);
}
.css-i9cy6v {
  width: 90% !important;
}
table {
  font-weight: normal;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  font-weight: normal;
  border: 1px solid #f6f6f6;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  font-weight: normal;
  background-color: #f6f6f6;
}
.boxxes_dt{
  width: 100%;
  height: auto;
  background: #161E40;
  padding: 10px 20px;
  border-radius: 7px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width:1650px) and (max-width: 1950px) {
  .head_container {
    max-width: 1620px !important;
  }
  .head_containers {
    max-width: 1750px !important;
  }
}
@media (min-width:1200px) and (max-width: 1440px) {
  .head_container {
    max-width: 1180px !important;
  }
}
@media (min-width: 1440px) and (max-width: 1650px){
  .thr_one {
    width: 140px !important;
  }
  .web_collection {
    width: 140px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1440px){
  .thr_one {
    width: 120px !important;
  }
  .web_collection {
    width: 120px !important;
  }
}
@media (min-width: 1100px) and (max-width: 1300px){
  .thr_one {
    width: 100px !important;
  }
  .web_collection {
    width: 100px !important;
  }
  .thr_three {
    font-size: 10px;
  }
  .sdfjsdogvodigd{
    font-size: 10px;
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .skdnskf{
    margin-top: 11px;
  }
}
.rc-slider-handle {
  background-color: #ffd400 !important;
  border: solid 2px #fdfdfd !important;
  opacity: unset !important;
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
    position: absolute;
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: 9px; 
}
@media (min-width: 726px) and (max-width: 1200px){
  .sdfvdfvddaeeee{
    height: 1100px !important;
  }
}






