.run_ord_eightsss {
    /* margin-top: -15px; */
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.02em;
    color: #000000;
}
.run_ord_sevensss {
    font-weight: 500;
    font-size: 12px;
    color: #161E40;
    /* margin-top: -20px; */
}
.run_ord_six {
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
    margin-top: -5px;
}
.run_ord_twelvess {
    position: relative;
    z-index: 1;
    margin: auto;
    margin-top: -10px;
    width: 85px;
    height: 22px;
    background: #F4B644;
    border-radius: 3px;
}
.run_ord_tenss {
    position: relative;
    margin: auto;
    margin-top: -10px;
    width: 85px;
    height: 22px;
    background: #6CC8BE;
    border-radius: 3px;
}
.compara1a {
    width: 93px;
    height: 17px;
    left: 27px;
    top: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin: auto;
    margin-top: 13px;
}
.compara2a {
    left: 47px;
    top: 17px;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin: auto;
}
.run_ord_ninea {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10px;
}