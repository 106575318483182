.donut-ring {
    position: relative;
    width: 60px;
    height: 60px;
}

.ring {
    position: absolute;
    width: 50%;
    height: 50%;
}

.ring.red {
    top: 0;
    left: 0;
    background-color:  #C4C4C4;
    border-radius: 100% 0 0 0;
}

.ring.redfinalll {
    top: 0;
    left: 0;
    background-color:  red;
    border-radius: 100% 0 0 0;
}


.ring.redsgnnfnd {
    top: 0;
    left: 0;
    background-color:  #cfcf00;
    border-radius: 100% 0 0 0;
}


.ring.redsgfghfghfd {
    top: 0;
    left: 0;
    background-color:  green;
    border-radius: 100% 0 0 0;
}

.ring.blue {
    top: 0;
    right: 0;
    background-color:  #C4C4C4;
    border-radius: 0 100% 0 0;
}
.ring.bluethreee {
    top: 0;
    right: 0;
    background-color:  #cfcf00;
    border-radius: 0 100% 0 0;
}
.ring.bluethreeeapp {
    top: 0;
    right: 0;
    background-color:  green ;
    border-radius: 0 100% 0 0;
}
.ring.bluethreeeappred {
    top: 0;
    right: 0;
    background-color:  red ;
    border-radius: 0 100% 0 0;
}
.ring.redone {
    top: 0;
    left: 0;
    background-color:  #F4B644;
    border-radius: 100% 0 0 0;
}

.ring.blueone {
    top: 0;
    right: 0;
    background-color:  #F4B644;
    border-radius: 0 100% 0 0;
}

.ring.orangeone {
    bottom: 0;
    left: 0;
    background-color: #F4B644;
    border-radius: 0 0 0 100%;
}

.ring.greenone {
    bottom: 0;
    right: 0;
    background-color: #F4B644;
    border-radius: 0 0 100% 0;
}

.ring.bluethree {
    top: 0;
    right: 0;
    background-color:  #F4B644;
    border-radius: 0 100% 0 0;
}
.ring.orange {
    bottom: 0;
    left: 0;
    background-color: #C4C4C4;
    border-radius: 0 0 0 100%;
}
.ring.orangegreenss {
    bottom: 0;
    left: 0;
    background-color: green;
    border-radius: 0 0 0 100%;
}
.ring.orangered444 {
    bottom: 0;
    left: 0;
    background-color: red;
    border-radius: 0 0 0 100%;
}
.ring.orangetwoss {
    bottom: 0;
    left: 0;
    background-color: #cfcf00;
    border-radius: 0 0 0 100%;
}
.ring.orangetwo {
    bottom: 0;
    left: 0;
    background-color: #F4B644;
    border-radius: 0 0 0 100%;
}

.ring.green {
    bottom: 0;
    right: 0;
    background-color: #F4B644;
    border-radius: 0 0 100% 0;
}

.ring.yellowonw { 
    bottom: 0;
    right: 0;
    background-color: #cfcf00;
    border-radius: 0 0 100% 0;
}

.ring.redones { 
    bottom: 0;
    right: 0;
    background-color: red;
    border-radius: 0 0 100% 0;
}

.cutout {
    width: 80%;
    height: 80%;
    background-color: white;
    position: absolute;
    top: 10%;
    left: 10%;
    border-radius: 50%;
    pointer-events: none;
}

.kyc_p_one{
    font-weight: 600;
    font-size: 10px;
    color: #000000;
    margin-top: 13px;
    margin-left: 10px;
}

.kyc_p_two {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    margin-left: 10px;
    margin-bottom: 5px;
}
.kyc_p_three{
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-left: 10px;
    margin-top: -20px;
}
.kyc_p_four{
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.kyc_img_one{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.kyc_cont{
    position: relative;
    text-align: center;
    color: white;
}
.kyc_centered{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.kyc_cont_two{
    width: 126px;
    height: 40px;
    left: 124.5px;
    top: 673.65px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
}
.kyc_line{
    border: 1px solid rgb(180, 180, 180);
    height: 23px;
}
.kyc_two_para{
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-bottom: 0px !important;
}
.kyc_in_one{
    background-color: #EAECEE !important;
}
.kyc_five{
    width: 100%;
    height: 100%;
}
.kyc_five_img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.kyc_five_p_one{
    font-weight: 600;
    font-size: 16px;
    color: #161E40;
    margin-top: -20px;
    text-align: center;
}
.kyc_five_p_two{
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: -15px;
    text-align: center;
}
.kyc_five_box{
    width: 150px;
    height: 44px;
    right: 112.5px;
    bottom: 330.17px;
    background: #F4B644;
    border-radius: 5px;
    margin: auto;
}
.kyc_five_p_three{
    padding-top: 10px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
.css-1pxlahx{
    margin-left: 110px;
    position: absolute;
    margin-top: 47px;
}
.kyc_fives{
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
}
.tikkk{
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
}