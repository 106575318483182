
.nav-tabs .nav-link.active {
    border-color :'unset' !important;
}
#progress-bar {
    display: table;
    width: 100%;
    margin: 0;
    table-layout: fixed;
    width: 100%;
    counter-reset: step;
}
#progress-bar li {
list-style-type: none;
display: table-cell;
width: 20%;
font-size: 10px;
position: relative;
}
#progress-bar li:before {
width: 50px;
height: 50px;
color: #212121;
content: counter(step);
counter-increment: step;
line-height: 50px;
font-size: 18px;
border: 1px solid #efefef;
display: block;
text-align: center;
border-radius: 50%;
background-color: #fff;
}
  #progress-bar li:after {
    width: 100%;
    height: 10px;
    content: "";
    position: absolute;
    background-color: #efefef;
    top: 25px;
    left: -90%;
    z-index: -1;
  }
  #progress-bar li:first-child:after {
    content: none;
  }
  #progress-bar li.step-done {
    color: #F4B644;
  }
  #progress-bar li.step-done:before {
    border-color: #F4B644;
    background-color: #F4B644;
    color: #fff;
    content: "";
    font-family: "FontAwesome";
  }
  #progress-bar li.step-done + li:after {
    background-color: #F4B644;
  }
  #progress-bar li.step-active {
    color: #000000;
  }
  #progress-bar li.step-active:before {
    border-color: #F4B644;
    color: #F4B644;
    font-weight: 700;
  }
  .det{
      color: rgb(117, 117, 117);
      margin-top: -10px;
  }
  .firr{
      font-weight: 700;
  }
  .headss{
      color:rgb(117, 117, 117);
  }
  .dets{
    margin-top: -6px;
}

.small {
  letter-spacing: 0.5px !important
  }
  hr {
  background-color: rgba(248, 248, 248, 0.667)
  }
  .bold {
  font-weight: 500
  }
  .change-color {
  color: #AB47BC !important
  }
  .card-2 {
  box-shadow: 1px 1px 3px 0px rgb(112, 115, 139)
  }
  .fa-circle.active {
  font-size: 8px;
  color: #AB47BC
  }
  .fa-circle {
  font-size: 8px;
  color: #aaa
  }
  .rounded {
  border-radius: 2.25rem !important
  }
  .progress-bar {
  background-color: #F4B644 !important
  }
  .progress {
  height: 5px !important;
  margin-bottom: 0
  }
  .invoice {
  position: relative;
  top: -70px
  }
  .Glasses {
  position: relative;
  top: -12px !important
  }
  .card-footer {
  background-color: #AB47BC;
  color: #fff
  }
  h2 {
  color: rgb(78, 0, 92);
  letter-spacing: 2px !important
  }
  .display-3 {
  font-weight: 500 !important
  }
  @media (max-width: 479px) {
  .invoice {
  position: relative;
  top: 7px
  }
  .border-line {
  border-right: 0px solid rgb(226, 206, 226) !important
  }
  }
  @media (max-width: 700px) {
  h2 {
  color: rgb(78, 0, 92);
  font-size: 17px
  }
  .display-3 {
  font-size: 28px;
  font-weight: 500 !important
  }
  }
  .card-footer small {
  letter-spacing: 7px !important;
  font-size: 12px
  }
  .border-line {
  border-right: 1px solid rgb(226, 206, 226)
  }