.qr_nines {
    /* margin-top: -10px; */
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}
.qr_sevena {
    padding: 8px 10px;
    width: 100%;
    height: 110px;
    background: #F4F4F4;
    border-radius: 5px;
}
.qr_eighta {
    font-weight: 600;
    font-size: 15px;
    color: #161E40;
}
.qr_tena {
    margin-top: 35px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #008000;
    border-radius: 5px;
}
.qr_tensa {
    margin-top: 35px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #F4B644;
    border-radius: 5px;
}
.qr_tenssa {
    margin-top: 15px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #C8202D;
    border-radius: 5px;
}
.run_ord_threec {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
}
.qr_twelvec {
    /* margin-top: -15px; */
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #9A9A9A;
}
.qr_ninemiss {
    margin-top: 10px;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
}
.qr_threenew {
    padding: 10px 16px 15px;
    /* position: fixed; */
    width: 100%;
    height: 69px;
    right: 0.87px;
    bottom: 0px;
    background: #FFFFFF;
    /* box-shadow: 0px -4px 10px rgb(0 0 0 / 5%); */
}