.pro_home_one{
    width: 61%;
    margin: auto;
}
@media (min-width: 727px) and (max-width: 2300px){
    .icon-loading {
        margin-top: -17px !important ;
        margin-left: 10px !important ;
        width: 50px !important ;
    }
}
@media (min-width: 0px) and (max-width: 726px){
    .swiper-container-fade .swiper-slide{
        background-color: rgb(255 255 255) !important;
        border-radius: 6px;
    }
}
.nav-tabs .nav-link{
    white-space: nowrap;
}
.pro_home_two{
    width: 100%;
    background-color: #fafafaa1;
    height: 70px;
    border-radius: 8px;
    padding: 8px;
}
.pro_cart_det_cart{
    height: 50px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #343538;
    display: inline-block;
    line-height: 1;
    z-index: 99;
    border-radius: 10px;
    padding: 19px 37px 7px !important;
}
.pro_home_three{
    width: 55px;
    height: 55px;
    border-radius: 50%;
}
.pro_home_four{
    margin-top: 17px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #000000;
}
.pro_home_five{
    width: 100%;
    background-color: #fafafaa1;
    border-radius: 8px;
    padding: 8px;
}
.pro_home_six{
    cursor: pointer;
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #000000; 
}
.pro_home_seven{
    width: 100%;
    background-color: #fafafaa1;
    padding: 20px;
    border-radius: 8px;
}
.pro_home_eight{
    font-size: 20px;
    font-weight: 600;
}
.pro_home_nine{
    font-size: 10px;
    margin-left: 10px;
    margin-top: 4px;
    font-weight: 400;
    color: #F4B644;
}
.pro_home_ten{
    font-weight: 600;
    margin-bottom: 10px;
}
.form-control:focus{
    box-shadow: unset !important;
}
.pro_home_eleven{
    font-weight: 600;
}
.pro_home_twelve{
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
}
.form-control{
    border: 1px solid #f5f1f1 !important;
}
.pro_home_fifteen{
    background-color: #fafafaa1;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
}
.pro_home_sixteen{
    width: 20px;
    height: 20px;
}
.txttsss{
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: #161E40;
    padding-bottom: 4px;
}
.ord_home_one{
    height: 2px !important;
    width: 30px;
    color:  #F4B644 !important;
}
.ord_home_ones{
    display: unset;
}
.ord_det_threes{
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}
.ord_det_fives{
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    color: #F4B644;
}
.check_change_ones{
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.his_twos{
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #9A9A9A;
}
.his_onesss{
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    color: #C8202D;
}
.his_twosss{
    font-weight: 600;
    font-size: 12px;
    text-align: right;
    color: black;
}
.ord_det_twelvesss{
    width: 100%;
    height: 39.97px;
    background: #008000;
    border-radius: 5px;
}
.his_ten{
    padding: 10px;
    width: 100%;
    height: auto !important;
    background: #F4F4F4;
    border-radius: 5px;
}
.his_eleven{
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
}
.his_twelve{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
    margin-top: -10px;
}
.his_thirteen{
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #F4B644;
}
.his_fourteen{
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #008000;
}
.his_sixteen{
    font-weight: 500;
    font-size: 10px;
    color: #9A9A9A;
    text-align: center;
    margin-top: -20px;
}
.his_twelves{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.his_sixteens{
    font-weight: 500;
    font-size: 10px;
    color: #9A9A9A;
    text-align: center;
}
.red_minus{
    margin-top: -40px;
    position: relative;
    margin-left: 17px;
}
.web_pending_one_p{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.web_pending_one_p_one{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}
.pppp_one{
    font-weight: 500;
    font-size: 10px;
    text-align: center;
}
.pppp_two{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #161E40;
}
