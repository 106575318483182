.pdp-slider {
    display: flex;
    width: 100%;
    height: 480px;
    max-height: 515px;
    justify-content: space-between;
    &__thumbs{
      width: 63px;
      .pdp-slider__thumb {
        margin: 10px 0;
      }
    }
    &__galleries{
      width: calc(100% - 85px);
      // background-color: #FBFBFB;
      &:hover {
        img {
          // opacity: 0;
        }
      }
      img {
        width: 90%;
        // opacity: 1;
        // transition: all .3s;
      }
    }
    &__thumb {
      width: 60px;
      height: 65px;
      border: 1.4px solid #ccc;
      border-radius: 2px;
      transition: border-color .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &--active{
        border-color: #0099FF; 
      }
      img {
        width: 90%;
      }
    }
  }

.pdp-slider__galleries img{
  margin: auto;
}

  @media (min-width: 992px) and (max-width: 1300px) {
    .pdp-slider {
      display: flex;
      width: 100%;
      height: 380px;
    }
  }
  @media (min-width: 1300px) and (max-width: 1500px) {
    .pdp-slider {
      display: flex;
      width: 100%;
      height: 500px;
    }
  }
  
  .slick-arrow {
    background: #999;
    border: 0;
  }