
* {
  box-sizing: border-box;
}
body {
  font-family: "Roboto" !important;
}
.openBtn {
  background: #f1f1f1;
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
}

.openBtn:hover {
  background: #bbb;
}

.overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
}

.overlay-content {
  position: relative;
  top: 46%;
  width: 80%;
  text-align: center;
  margin-top: 30px;
  margin: auto;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
  cursor: pointer;
  color: white;
}

.overlay .closebtn:hover {
  color: #ccc;
}

.overlay input[type=text] {
  padding: 15px;
  font-size: 17px;
  border: none;
  float: left;
  width: 80%;
  background: white;
}

.overlay input[type=text]:hover {
  background: #f1f1f1;
}

.overlay button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.overlay button:hover {
  background: #bbb;
}
.header_nav_tit_color{
  color: #F4B644 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.header_nav_tit_color_two{
  color: black !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}
.header_nav_tit_color_pro{
  color: #F4B644 !important;
} 

.head_search{
  margin-top: -10px;
  width: 400px;
  border-radius: 5px;
  background-color: #F4F4F4;
  border: unset !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
  padding-right: 10px;
  /* background: url('') no-repeat scroll 7px 7px; */
}
.head_search_img{
  position: absolute;
  margin-left: 5px;
}
.head_search_imgs{
  position: absolute;
  margin-left: -4px;
}
.arroeeww{
  margin-top: 20px;
  margin-left: 20px;
}
.profile_bbbtn{
  width: 100%;
  height: 45px;
  background-color: #F4B644;
  margin-top: 30px;
  border-radius: 6px;
}
.profile_bbbtn_text{
  color: white;
  text-align: center;
  padding-top: 12px;
  font-weight: 600;
}

@media (min-width: 1600px) and (max-width: 2523px) {
  .ord_det_six {
    width: 100px !important ;
  }
}

@media (min-width: 726px) and (max-width: 1000px) {
  .main-menu nav ul li > a {
    font-size: 13px !important;
    margin-top: 28px;
    font-weight: 500 !important;
  }
}

.edit_address{
  width: 20%;
  height: 25px;
  margin-top: 5px;
  background-color: #161E40;
  border-radius: 6px;
  margin-right: 10px;
}
.delete_address{
  width: 20%;
  height: 25px;
  margin-top: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #161E40;
  border-radius: 6px;
}
.web_cart_col_moness{
  padding: 10px;
  width: 90%;
  background-color: rgba(244, 182, 68, 0.25);
  border-radius: 5px;
  border: 1px solid #F4B644;
}
.web_cart_col_mfivess{
  padding: 10px;
  width: 90%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #C4C4C4;
}
.edit_address_p{
  color: white;
  text-align: center;
  margin-top: 2px;
  font-size: 10px;
  cursor: pointer;
}
.delete_address_p {
  color: #161E40;
  text-align: center;
  font-size: 10px;
  cursor: pointer;
}
.over_model{
  overflow: scroll;
  height: 600px;
  overflow-x: hidden;
}
.react-date-picker__wrapper {
  background-color: rgb(245, 245, 245) !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 1; /* Firefox */
}
/* .pdp-slider__galleries img{
  opacity: 1 !important;
} */
@media (min-width: 0px) and (max-width: 420px) {
  .propara5 {
    font-size: 9px !important;
  }
  .propara6{
    font-size: 9px !important;
  }
}
.more_items{
  text-align: center;
  margin-top: -53px;
  margin-left: 5px;
  color: white;
}
.react-date-picker__clear-button{
  visibility: hidden;
}

@media (min-width: 600px) and (max-width: 1200px) {
 .web_cart_col_4 {
    margin-top: 40px;
  }
}

.step_info{
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px solid #e3e8ec;
  border-radius: 50%;
  background-color: #F4B644;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.timmmerrrrr_prr  div  div {
  flex-direction: column !important;
}

.ord_det_sevenmobbb {
  width: 100%;
  /* height: 86px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.ord_det_seven_orrrrdd {
  width: 100%;
  height: 55px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.ord_det_threes_ordd {
  margin-top: -17px;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

.ord_det_fives_orddd {
  text-align: right;
  font-weight: 500;
  font-size: 12px;
  color: #F4B644;
  margin-top: -17px;
}
.ed_pro_one_web {
  /* z-index: -1; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.ed_pro_two_web_pro {
  cursor: pointer;
  z-index: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  align-items: center;
  padding: 0px 5px;
  width: 114px;
  height: 28px;
  background: #F4B644;
  border-radius: 8px;
}
.chat_scrll{
  scroll-snap-align: end;
}
.supp_two_web {
  margin-top: 20px;
  padding: 15px;
  width: 100%;
  height: 64px;
  background: #F4F4F4;
  border-radius: 5px;
  /* position: fixed; */
  right: 0;
  left: 0;
  bottom: -2px !important;
}
.supp_two_webchattttt{
  padding: 15px;
  width: 100%;
  height: auto;
  background: #F4F4F4;
  /* border-radius: 5px; */
  margin-bottom: 20px;
}
.scroll_padding_bottom{
  scroll-padding-bottom: 100%;
}

.kyc_p_two_changed{
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  margin-left: 10px;
}
.react-date-picker__inputGroup{
  pointer-events: none !important;
  cursor: default !important;
}
.pdp-slider{
  max-height: unset !important;
  justify-content: unset !important;
}
.pdp-slider__thumbs + div{
  width: 100% !important;
}

.pdp-slider__thumf + div{
  width: 100% !important;
}
.service_five__teen {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */
}
.check_change_twosssssss{
  margin: auto;
  width: 89%;
}
.check_change_twosssssssssss {
  /* height: 100%; */
  margin: auto;
  width: 85px;
}
.foot_indexx{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.slick-track{
  height: auto !important;
}
.slliderr_bosss {
  height: 261px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: 60%;
}
.qr_four_lite {
  padding: 10px 20px;
  width: 100%;
  height: 44px;
  background: #fff0d4;
  border-radius: 5px;
}
.cooooompraaa{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #F4B644;
  margin-top: 26px;
}
@media (min-width: 0px) and (max-width: 726px) {
  .gwZiig{
    overflow-x :auto !important;
    height: 34px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .home_pro_cont{
    height: 400px !important;
  }
}

@media (min-width: 990px) and (max-width: 1100px) {
  .home_pro_cont{
    height: 350px !important;
  }
}


@media (min-width: 1200px) and (max-width: 1400px) {

}

@media (min-width: 726px) and (max-width: 991px) {
  .home_pro_cont{
    height: 600px !important;
  }
}

@media (min-width: 726px) and (max-width: 1301px) {
  .footeresone{
    font-size: 12px !important;
  }
  .footerestwo{
    font-size: 11px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1800px) {
  .header-right-wrap{
    margin-right: 20px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1530px) {
  .red_minusss{
    margin-left: unset !important;
  }
}
@media (min-width: 726px) and (max-width: 1000px) {
  .red_minusss{
    margin-left: unset !important;
  }
}
.slider-h9-mrg {
  margin-top: 30px !important;
}



.thumbs {
  height: 400px;
  max-width: '100%';
  margin: 30px auto;
  padding: 0 100px;
  position: relative;
}
.thumf {
  height: 40px;
  max-width: '40%';
  margin: 30px auto;
  padding: 0 10px;
  position: relative;
}

.thumbs .alice-carousel {
position: relative;
margin: auto;
direction: ltr;
}
.thumf .alice-carousel {
  position: relative;
  margin: auto;
  direction: ltr;
  }

.thumbs .btn-prev {
left: -40px;
position: absolute;
top: 160px;
font-size: 50px;
color: #c7cdde;
cursor: pointer;
}
.thumf .btn-prev {
  left: -40px;
  position: absolute;
  top: 16px;
  font-size: 50px;
  color: #c7cdde;
  cursor: pointer;
  }
.alice-carousel__wrapper{
  padding-left: 30px !important;
}

 .thumbs .btn-next {
  right: -40px;
  position: absolute;
  top: 160px;
  font-size: 50px;
  color: #c7cdde;
  cursor: pointer;
}

.thumf .btn-next {
  right: -40px;
  position: absolute;
  top: 16px;
  font-size: 50px;
  color: #c7cdde;
  cursor: pointer;
}
#root{
  overflow-y : hidden;
  overflow-x: hidden;
}
.addclass{
  width : '100%';
  height : 265px;
  order: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 10px rgba(166, 171, 189, 0.25);
  border-radius: 10px;
  padding: 40px;
}
/* .alice-carousel {
  width: 99% !important;
} */
.filteeer{
  width: 100%;
  height: auto ;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 10px rgba(166, 171, 189, 0.25);
  border-radius: 5px;
}
.filteeeerlol{
  width: 47px;
  height: 20px;
  border-radius: 50px;
  padding: 2px 8px;
  border: 1px solid #E6E8F0;
}
.filteeeerlols{
  width: auto;
  height: 20px;
  border-radius: 50px;
  padding: 2px 8px;
  border: 1px solid #E6E8F0;
}
.svndlkvndklf{
  margin-top: 5px;
  width: 9px;
  height: 6px;
  margin-left: 5px;
  cursor: pointer;
}
.svndlkvndklfss{
  margin-top: 5px;
  width: 9px;
  height: 6px;
  margin-left: 5px;
  transform: rotate(180deg);
  cursor: pointer;
}
.scrooooll{
  height: 143px;
  overflow-y: auto;
}
.scrooooll::-webkit-scrollbar-track {
  /* border: 1px solid #000;
  padding: 2px 0;
  background-color: #c7c7c7; */
}

.scrooooll::-webkit-scrollbar {
  width: 4px;
}

.scrooooll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d4d4d4;
  border: 1px solid rgb(182, 182, 182);
}
.vndkhjdhgsdibgbdgb{
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.vndkhjdhgsdibgbdgb::-webkit-scrollbar {
  width: 0px;
}
.vndkhjdhgsdibgbdgb::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d4d4d4;
  border: 1px solid rgb(182, 182, 182);
}



.scrooooll::-webkit-scrollbar-thumf {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d4d4d4;
  border: 1px solid rgb(182, 182, 182);
}
.vndkhjdhgsdibgbdgb{
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}
.vndkhjdhgsdibgbdgb::-webkit-scrollbar {
  width: 0px;
}
.vndkhjdhgsdibgbdgb::-webkit-scrollbar-thumf {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #d4d4d4;
  border: 1px solid rgb(182, 182, 182);
}

.left {
  display: flex;
  /* height: 70vh; */
}
.left_1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.img_wrap {
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  cursor: pointer;
}
.img_wrap img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.left_2 {
  z-index: 1;
}
.left_2 img {
  width: 500px;
  height: 100%;
  object-fit: contain;
  background-color: white;
}
.left_2 div div img {
  margin-left: 224px;
}
.left_2 div div {
  background-color: white;
}
.rightttt {
  z-index: 1;
}
/* .active {
  border: 2px solid #e77600;
} */
@media (min-width:726px) and (max-width: 890px) {
  .head_search {
    width: 295px !important;
  }
}
.lllllmp{
  font-size: 14px;
    text-align: center;
    font-weight: 500;
    color: rgb(255, 255, 255);
}
@media (min-width:1200px) and (max-width: 1300px) {
  .lllllmp {
    font-size: 10px;
  }
}
@media (min-width:726px) and (max-width: 1100px) {
  .lllllmp {
    font-size: 10px;
  }
}

@-moz-document url-prefix() {
  .bounce{
      margin-left: auto;
      width: 100%;
  }
}
.loas_login_lll{
  margin-top: 30px;
}

.lolllllo > .sc-bdVaJa > .sc-bwzfXH {
  /* height: 55px; */
}

.aaacafoooo{
  z-index: 1;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

@media (min-width:1100px) and (max-width: 1950px) {
  .thumbs .btn-next {
    top: 135px;
  }
  .thumbs .btn-prev {
    top: 135px;
  }
  .thumbs {
    height: 320px;
  }
}

@media (min-width: 726px) and (max-width: 990px) {
  .home_pro_cont{
    height: 363px !important;
  }
}
.slvmsdfm{
  width: 100%;
  height: 40px;
  background-color:  'white';
  border-width: 2px;
  border-style: solid;
  border-color: #eceff8;
  border-radius: 6px;
  padding: 5px;
}
.pro-details-wishlist{
  position: relative;
  left: -38px;
  top: 10px;

}
@media (min-width: 726px) and (max-width: 992px) {
  .pro-details-wishlist {
    position: relative;
    left: 625px;
    top: -855px;
}
.whyycontainerrr{
  height: 980px;
}

}

@media (min-width: 0px) and (max-width: 726px) {
  .cool2 {
    width: auto !important;
    height: 250px !important;
    padding: 14px 10px !important;
  }
  .sfvdkgehhrfuddd{
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }
}


@media (min-width: 770px) and (max-width:948px) {
  .sfvdkgehhrfuddd{
    width: 20px !important;
    height: 20px !important;
    margin-top: 2px !important;
  }

  .cool2 {
    height: 208px !important;
  }
}
.react-date-picker{
  width: 100%;
}
.react-date-picker--closed{
  width: 100%;
}
.react-date-picker--disabled{
  width: 100%;
}
.adfsuifwewc{
  height: 1250px;
}
.propara17o7 {
  padding-top: 14px;
}
/* .cartttghbf{
  background: url("assets/ioon/cart.png") no-repeat;
  background-size:     cover;   
  background-position: center center;
}
.cartttghbf:hover{
  background: url("assets/ioon/carthov.png") no-repeat;
  
} */
.sgdfgfdgd{
  display: none;
}
/* .ghrtrtehhh:hover{
  display: none;
} */
.ghrtrtehhh:hover ~ .sgdfgfdgd{
  display: block;
}
.hrfhfgdgdggfb{
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  color: #959595;
  margin-top: 6px;
  margin-right: 4px;
  text-decoration: line-through;
}
.grrreeeennn4{
  bottom: 0;
  right: 0;
  background-color: green;
  border-radius: 0 0 100% 0;
}
.grrreeeennn2{
  top: 0;
  right: 0;
  background-color: green;
  border-radius: 0 100% 0 0;
}
.grrreeeennn3{
  bottom: 0;
  left: 0;
  background-color: green;
  border-radius: 0 0 0 100%;
}
.grrreeeennn1{
  top: 0;
  left: 0;
  background-color: green;
  border-radius: 100% 0 0 0;
}
.yhdghkbfgh > .sc-bdVaJa  {
  background-color: rgb(224, 224, 224);
  padding-top: 10px;
}

.btn-2 {
  background-image: linear-gradient(to right, #0b0c5e 0%, #0b0c5e 51%, #0b0c5e 100%);
  color:#161E40;
  
}

.gradient-button {
  position: absolute;
  z-index: 1;
  display: block;
 margin-bottom:3px;
	height: 3rem;
	width: 12rem;
  margin: auto;
	background: transparent linear-gradient(to top left, rgba(249,208,129,.2) 0%, rgba(227,2,62,.2) 40%, rgba(49,128,135,.2) 100%);
	border:solid transparent;
	border-image-source: linear-gradient(to top left, rgba(249,208,129,1) 0%, rgba(227,2,62,1) 40%, rgba(49,128,135,1) 100%);
	border-image-slice: 1;
  transition: transform .25s;
  letter-spacing: .2rem;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #333;
  
}

.btn-secondary{
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#797b7f+0,b5b8bf+50,8e9397+51,8e9397+71,828589+100 */
   background: #797b7f;
  /* Old browsers */
   background: -moz-linear-gradient(-45deg, #797b7f 0%, #b5b8bf 50%, #8e9397 51%, #8e9397 71%, #828589 100%);
  /* FF3.6-15 */
   background: -webkit-linear-gradient(-45deg, #797b7f 0%,#b5b8bf 50%,#8e9397 51%,#8e9397 71%,#828589 100%);
  /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(135deg, #797b7f 0%,#b5b8bf 50%,#8e9397 51%,#8e9397 71%,#828589 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#797b7f', endColorstr='#828589',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
   background-size: 400% 400%;
   -webkit-animation:  3s ease infinite;
   -moz-animation:  3s ease infinite;
   animation:  3s ease infinite;
   -webkit-animation: 3s ease infinite;
   -moz-animation: 3s ease infinite;
   animation: 3s ease infinite;
   border: medium none;
}

  .lapspec{
    color:#161E40;
  }

.bloghead{
  color:#161E40;
}