.run_ord_sevensa {
    /* margin-top: -15px; */
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.mob_footssssa {
    padding: 10px 16px 15px;
    width: 100%;
    height: 77px;
    right: 0px;
    bottom: 0px;
    background: #161E40;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 5%);
    border-radius: 10px;
}
.compara333a {
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #000000;
    /* margin-top: -20px; */
}