.mob_payment{
    width: 100%;
    height: 44px;
    left: 16px;
    top: 169.78px;
    background: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
}
.Mob_pay_p_one{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #F4B644;
    padding-top: 10px;
}
.Mob_pay_p_two{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.Mob_pay_p_three{
    font-weight: 500;
    font-size: 12px;
    color: #2C2A2A;
}
.Mob_pay_p_threes{
    font-weight: 500;
    font-size: 12px;
    color: #2C2A2A;
    text-align: right;
}
.mob_pay_line{
    display: block;
    margin-left: auto;
    width: 84.17px;
    height: 0px;
    left: 275.52px;
    top: 382.78px;
    border: 1px solid #bdbdbd;
}
.mob_pay_tot{
    text-align: right;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #2C2A2A;
}
.mob_pay_check{
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    color: #000000;
}
.pay_foot_one{
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #000000;
}
.pay_foot_two{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #000000;
}