.second-tops{
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
.ccaaat{
    font-weight: 500;
    line-height: 17px;
    color: #000000;
}
.compara222{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #000000;
    margin-top: 10px;
}
.compara333{
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #000000;
    margin-top: -20px;
}
.mob_footss{
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
    height: 72px;
    background-color: #ffffff;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
}
.headset{
    margin-top: 17px;
    margin-right: 10px;
}
.nextonee{
    margin: auto;
}



.boc111 {
    width: 100%;
    height: 40px;
    position: relative;
    background: rgba(244, 182, 68, 0.25);
}
.boc111:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}
.boc111:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid rgba(244, 182, 68, 0.25);
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.boc11123 {
    width: 100%;
    height: 40px;
    position: relative;
    background: #F4B644;
}
.boc11123:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}
.boc11123:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #F4B644;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}



.boc1114 {
    width: 100%;
    height: 40px;
    position: relative;
    background: #C4C4C4;
}
.boc1114:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}
.boc1114:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #C4C4C4;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}




.boc1112 {
    width: 100%;
    height: 40px;
    position: relative;
    background: #F4B644;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.boc1112:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #F4B644;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.boc11134 {
    width: 100%;
    height: 40px;
    position: relative;
    background: rgba(244, 182, 68, 0.25);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.boc11134:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}



.boc1113 {
    width: 100%;
    height: 40px;
    position: relative;
    background: #C4C4C4;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.boc1113:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}


.po{
    font-weight: 500;
    font-size: 9px;
    color: #FFFFFF;
    text-align: center;
    padding-top: 8px;
}
.po2{
    font-weight: 500;
    font-size: 9px;
    color: #F4B644;
    text-align: center;
    padding-top: 8px;
    padding-left: 15px;
}
.po3{
    font-weight: 500;
    font-size: 9px;
    color: #000000;
    text-align: center;
    padding-top: 8px;
    padding-left: 15px;
}
.po4{
    font-weight: 500;
    font-size: 9px;
    color: #000000;
    text-align: center;
    padding-top: 8px;
    padding-left: 15px;
}
.cart_ppp{
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.cart_ppp2{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.cart_ppp3{
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #F4B644;
}

.containneerr{
    margin-top: 15px;
    width: 100%;
    height: 'auto';
    left: -0.1px;
    top: 62.16px;
    background: rgba(244, 182, 68, 0.25);
    border: 1px solid #F4B644;
    box-sizing: border-box;
    border-radius: 5px;
}
.rob_obe{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #000000;
}
.rob_two{
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    line-height: 12px;
    margin-top: -4px;
}
input[type="radio"]:checked {
    background-color: #F4B644 !important;
}
.containneerrr{
    margin-top: 15px;
    width: 100%;
    height: 'auto';
    left: -0.1px;
    top: 62.16px;
    background: #ffffff;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 5px;
}
.card_final_p{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.final_cooon{
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
}
.paraonesss{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
    margin-top: -10px;
}
.paraonessso{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #161E40;
    margin-top: -10px;
}
.chareee{
    width: 100px;
    height: 25px !important;
    background-color: white !important;
}
.chareee1{
    height: 25px;
    border-radius: 10px;
    background-color: #F4B644;
    border-color: #F4B644;
    border-style: solid;
    border-width: 2px;
}

  