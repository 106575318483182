.pdp-slider__mobile {
    width: 100%;
    max-width: 480px;
    .mobile-gallery {
      border: 1px solid red;
      width: 478px;
      position: relative;
      img {
        width: 90%;
        margin: 0 auto;
        vertical-align: middle;
      }
      .preview {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom:0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;
      }
    }
    .slick-dots {
      display: flex!important;
      justify-content: center;
      position: relative;
      top: -10px;
      li {
        list-style: none;
        margin: 0 2px;
        &.slick-active {
          button {
            background: #FF8702;
            border: 1px solid #FF8702;
          }
        }
      }
      button {
        text-indent: -99999px;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: white;
        border: 1px solid #D8D8D8;
      }
    }
  }
  