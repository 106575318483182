.ord_det_one{
    align-items: center;
    padding: 25px;
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 10px;
}
.ord_det_two{
    font-weight: 500;
    font-size: 12px;
    color: #9A9A9A;
}
.ord_det_three{
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    margin-top: -17px;
}
.ord_det_four{
    font-weight: 600;
    font-size: 16px;
    color: #161E40;
    text-align: right;
}
.ord_det_five{
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    color: #F4B644;
    margin-top: -17px;
}
.ord_det_six{
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 4.98139px;
}
.ord_det_seven{
    height: 85px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}
.ord_det_eight{
    font-weight: 500;
    line-height: 15px;
    color: #000000;
    font-size: 12px;
    text-align: center;

}
.ord_det_eights{
    padding-top: 4px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
}
.ord_det_eightss{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
}
.ord_det_nine{
    width: 100%;
    height: 39.97px;
    background: #161E40;
    border-radius: 5px;
}
.ord_det_ten{
    width: 100%;
    height: 39.97px;
    border: 1px solid #161E40;
    border-radius: 5px;
}
.ord_det_eleven{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #161E40;
    padding-top: 7px;
}
.ord_det_twelve{
    padding-top: 7px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}
.track_one{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.track_two{
    margin-top: -15px;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.track_three{
    align-items: center;
    padding: 10px 15px 10px 10px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #F4B644;
    box-sizing: border-box;
    border-radius: 5px;
}
.track_four{
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #161E40;
}
.track_five{
    margin-top: -5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.switch_order_one{
    font-weight: 500;
    font-size: 14px;
    color: #424242;
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    top: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.check_change{
    padding: 15px;
    width: 100%;
    height: 110px;
    background: #F4F4F4;
    border-radius: 5px;
}
.check_change_one{
    margin-top: -13px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.check_change_two{
    width: 60%;
    /* margin-top: 15px; */
    height: 100%;
    margin: auto;
}
.check_change_twoss{
    width: 100%;
    margin-top: 10px !important;
    /* height: 100%; */
    margin: auto;
}
.compara2{
    width: unset !important;
}
.run_ord_one{
    width: 100%;
    height: 130px;
    background: rgba(244, 182, 68, 0.25);
    border-radius: 10px;
}
.run_ord_two{
    margin-top: 35px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
}
.run_ord_three{
    margin-top: -10px;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    color: #000000;
}
.run_ord_four{
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}
.run_ord_five{
    margin: auto;
    padding: 15px;
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
}
.run_ord_six{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
}
.run_ord_seven{
    font-weight: 500;
    font-size: 12px;
    color: #161E40;
    margin-top: -20px;
}
.run_ord_eight{
    margin-top: -15px;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.02em;
    color: #000000;
}
.run_ord_nine{
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    margin-top: -9px;
    margin-left: -20px;
}
.run_ord_ninethousand{
    display: block;
    /* margin-left: auto; */
    margin-right: auto;
    /* margin-top: -9px; */
    margin-left: -20px;
}
.run_ord_ten{
    position: absolute;
    margin: auto;
    margin-top: -10px;
    width: 85px;
    height: 22px;
    background: #6CC8BE;
    border-radius: 3px;
}
.run_ord_eleven{
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
}
.run_ord_twelve{
    position: absolute;
    margin: auto;
    margin-top: -10px;
    width: 85px;
    height: 22px;
    background: #F4B644;
    border-radius: 3px;
}
.run_ord_twelvee{
    width: 100%;
    height: 0px;
    opacity: 0.2;
    border: 2px dashed #161E40;
}
.mob_footssss{
    position: fixed;
    padding: 10px 16px 15px;
    width: 100%;
    height: 77px;
    right: 0px;
    bottom: 0px;
    background: #161E40;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
}
.compara2223{
    color: white !important;
}
.renew_one{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.renew_two{
    margin-top: -10px;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.renew_three{
    margin-top: -10px;
}
.renew_four{
    margin-top: -10px;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.renew_five{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.run_ord_sevens{
    margin-top: -15px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.run_ord_fives{
    width: 100%;
    padding: 10px;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
}
.run_ord_eights{
    margin-top: -5px;
    font-weight: 500;
    font-size: 9.70835px;
    letter-spacing: 0.02em;
    color: #707070;
}
.mob_footsss{
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
    height: 72px;
    background-color: #161E40;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 5%);
}
.pay_foot_ones{
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #ffffff;
}
.pay_foot_twos{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}
.run_prev_one{
    width: 100%;
    height: 44px;
    align-items: center;
    padding: 10px 20px;
    background: #161E40;
    border-radius: 5px;
}
.run_prev_two{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}
.re_cart_one{
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.re_cart_two{
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.re_cart_three{
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #F4B644;
}
.re_cart_four{
    padding: 10px 15px 10px 10px;
    width: 100%;
    height: 88px;
    background: rgba(244, 182, 68, 0.25);
    border: 1px solid #F4B644;
    border-radius: 5px;
}
.re_cart_five{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.re_cart_six{
    margin-top: -5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #000000;
}
.re_cart_seven{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}
.re_cart_eight{
    padding: 10px;
    width: 100%;
    height: 118px;
    background: #F4F4F4;
    border-radius: 5px;
}
.re_cart_nine{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
}
.re_cart_ten{
    margin-top: -15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #161E40;
}
.re_cart_eleven{
    margin-top: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.re_cart_twelve{
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    height: 75px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
}
.re_cart_thirteen{
    margin: auto;
    padding: 10px 20px;
    width: 100%;
    height: 50px;
    background: #F4B644;
    border-radius: 5px;
}
.re_cart_fourteen{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
.re_cart_fifteen{
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: absolute;
    width: 50px;
    height: 44px;
    right: 31px;
    bottom: 96px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.qr_one{
    margin-top: 50px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #000000;
}
.qr_two{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #000000;
}
.qr_three{
    padding: 10px 16px 15px;
    position: fixed;
    width: 100%;
    height: 69px;
    right: 0.87px;
    bottom: 0px;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
}
.qr_four{
    padding: 10px 20px;
    width: 100%;
    height: 44px;
    background: #F4B644;
    border-radius: 5px;
}
.qr_five{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}
.qr_six{
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #F4B644;
}
.qr_seven{
    padding: 8px 10px;
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 5px;
}
.qr_eight{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.qr_nine{
    margin-top: -10px;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
}
.qr_ten{
    margin-top: 15px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #008000;
    border-radius: 5px;
}
.qr_eleven{
    padding-top: 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
}
.qr_twelve{
    margin-top: -15px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #9A9A9A;
}
.qr_tens{
    margin-top: 15px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #F4B644;
    border-radius: 5px;
}
.qr_tenss{
    margin-top: 15px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: #C8202D;
    border-radius: 5px;
}
.qr_tensss{
    margin-top: 15px;
    width: 100%;
    height: 28px;
    left: 248px;
    top: 18.71px;
    background: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
}
.qr_elevens{
    padding-top: 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #F4B644;
}
.qr_fifteen{
    padding: 10px;
    position: fixed;
    width: 100%;
    height: 75px;
    right: 0px;
    bottom: 0px;
    background: #FFFFFF;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
}
.qr_sixteen{
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    width: 343px;
    height: 44px;
    background: #F4B644;
    border-radius: 5px;
}
.qr_sixteens{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
.qr_seventeen{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}
.qr_sighteen{
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.qr_twenty{
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}
.qr_twentyone{
    padding: 8px;
    width: 100%;
    height: 90px; 
    background: #F4F4F4;
    border-radius: 5px;
}
.qr_twentytwo{
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161E40;
}
.qr_twentythree{
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.four_veri_one{
    margin: auto;
    width: 80%;
    height: 78px;
    border: 2px solid #424D59;
    border-radius: 5px;
}
.four_veri_two{
    margin-top: 25px;
    text-align: center;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: 0.2em;
    color: #424D59;
}
.four_veri_five{
    padding: 10px;
    width: 100%;
    height: 189.64px;
    background: #F4F4F4;
    border-radius: 5px;
}
.four_veri_six{
    font-weight: 900;
    font-size: 12px;
    color: #000000;
}
.qr_sighteenen{
    font-weight: 500;
    font-size: 12px;
    color: #000000;
}
.hand_one{
    font-weight: 600;
    font-size: 10px;
    color: rgba(22, 30, 64, 0.5);
}
.hand_two{
    /* margin-top: -18px; */
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.hand_three{
    margin-top: -15px;
    line-height: 15px;
    font-weight: 500;
    font-size: 10px;
    color: #676767;
}
.hand_four{
    margin-bottom: -7px;
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}
.hand_five{
    margin-bottom: -7px;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #F4B644;
}
.service_one{
    font-weight: 600;
    font-size: 12px;
    color: #000000;
}
.service_two{
    padding: 15px;
    width: 100%;
    height: 78px;
    background: #F4F4F4;
    border-radius: 5px;
}
.service_three{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.service_four{
    margin-top: -10px;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
}
.service_five{
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 10px; */
}
.service_six{
    margin-top: 10px;
    width: 100%;
    height: 28px;
    background: rgba(244, 182, 68, 0.25);
    border-radius: 5px;
}
.service_seven{
    padding-top: 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #F4B644;
}
.service_sevens{
    padding-top: 3px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
}
.service_sixs{
    margin-top: 10px;
    width: 100%;
    height: 28px;
    background: #F4B644;
    border-radius: 5px;
}
.hand_twossss{
    font-weight: 600;
    font-size: 12px;
    color: #161E40;
}
.react-responsive-modal-modal{
    width: 80%;
    margin: auto;
    padding: 10px !important;
    border-radius: 10px !important;
}
.hand_foot_one{
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #161E40;
}
.hand_foot_two{
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #161E40;
}
.hand_foot_three{
    margin: auto;
    width: 148px;
    height: 44px;
    border: 1px solid #161E40;
    border-radius: 5px;
}
.hand_foot_five{
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: #161E40;
}
input:checked + .slider {
    background-color: #161E40 !important;
}
.peeerrrr{
    margin-bottom: 0px !important;
}