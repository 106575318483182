.kyc_p_threes {
    font-weight: 500;
    font-size: 12px;
    color: #000000;
    margin-left: -17px;
}
.compara3333 {
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #000000;
}
.kyc_five_p_twos {
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
}
.tikkks {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mob_footssa{
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
    height: 72px;
    right: 0;
    bottom: 0;
    left: 0;
}
.mob_footsssa {
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 16px;
    width: 100%;
    height: 72px;
    background-color: #161E40;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0px -4px 10px rgb(0 0 0 / 5%);
}
.camera{
    width: 100%;
    height: 400px;
    border-color: #000000;
    border-width: 2px;
    border-style: solid;
    border-radius: 6px;
}
.kyc_cont_two_upload{
    display:inline-block;
    position:relative
}
.kyc_cont_two_upload_two{
    pointer-events:none;
}
.kyc_cont_two_upload_three[type=file]{
    opacity:0;
    position:absolute;
    top:-7px;
    left:0;
    right:0;
    bottom:0;
}
.img_opacttty{
    opacity: .2;
}
.fi_kyc{
    width: 122px;
    height: 40px;
    left: 15.7px;
    top: 680.32px;
    border: 1px solid #161E40;
    border-radius: 5px;
}